
import CpcbOverallSummary from "../Cpcb/OverallSummary/OverallSummary";

const CpcbDashboard = () => {


  console.log("cpcb dashboard")

  return (
    <>
      <CpcbOverallSummary/>
    </>
  );
};

export default CpcbDashboard;


// <!-- Start of HubSpot Embed Code -->
//   <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/44558521.js"></script>
// <!-- End of HubSpot Embed Code -->




import React, { useState, useEffect } from "react";
import { Select, DatePicker, Checkbox } from "antd";
import moment from "moment";
import "../CustomReports/CustomReport.css";
import Loader from "../../global/Loader/Loader";
import { GoNote } from "react-icons/go";
import axios from "axios";
import { AiOutlineFileDone } from "react-icons/ai";
import { MdKeyboardArrowLeft, MdOutlineErrorOutline } from "react-icons/md";
import { TbReportOff } from "react-icons/tb";
import {
  useCategoryDataQuery,
  useSiteNameQuery,
  useGetSelectedSiteDataQuery,
} from "../../Store/Slices/generateReportSlice";
import Joyride, { STATUS } from "react-joyride";
import FilteredReportGraph from "../CustomReports/FilteredReportGraph";
import FilteredReportTable from "../CustomReports/FilteredReportTable";
import ReportBuildLoader from "../../global/ReportBuilder/ReportBuildLoader";
import MedianReportDataTable from "../CustomReports/MedianReportDataTable";
import CombinedReportTable from "../CustomReports/CombinedReportTable";

const { Option } = Select;
const { RangePicker } = DatePicker;

const AverageGenerateReport = () => {
  const [category, setCategory] = useState("");
  const [siteId, setSiteId] = useState("");
  const [station, setStation] = useState("");
  const [parameters, setParameters] = useState([]);
  const [timeInterval, setTimeInterval] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [averageReport, setAverageReport] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isError, setIsError] = useState();
  const [medianReport, setMedianReport] = useState();
  const [dateSelectionError, setDateSelectionError] = useState(false);
  const [firstTimeLogin, setFirstTimeLogin] = useState(false);
  const [siteName, setSiteName] = useState("");
  const [siteNamesOptions, setSiteNamesOptions] = useState([]);
  const [selectAllParameters, setSelectAllParameters] = useState(false);
  const userType = localStorage.getItem("role");
  const userId = localStorage.getItem("siteId");

  console.log("sii", userId);

  const [{ run, steps }, setState] = useState({
    run: true,
    steps: [
      {
        content: <h2>Lets take a look at how you can Generate Report 📄 </h2>,
        locale: { skip: <strong>SKIP</strong> },
        placement: "center",
        target: "body",
      },
      {
        content: <h2>Select Category</h2>,
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: "#category",
        disabled: userType === "opreator",
      },
      {
        content: <h2> Select Site Name </h2>,
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: "#siteName",
        disabled: userType === "opreator",
      },
      {
        content: <h2> Select Station </h2>,
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: "#stationName",
      },
      {
        content: <h2>Select Parameters</h2>,
        locale: { skip: <strong>SKIP</strong> },
        target: "#parameters",
      },
      {
        content: <h2>Select Time Interval</h2>,
        locale: { skip: <strong>SKIP</strong> },
        target: "#timeInterval",
      },
      {
        content: <h2>Select Valide Start and End Date</h2>,
        locale: { skip: <strong>SKIP</strong> },
        target: "#dateRange",
      },
      {
        content: (
          <h2>Check this box if you want report in graph format also</h2>
        ),
        locale: { skip: <strong>SKIP</strong> },
        target: "#graphCheckbox",
      },
      {
        content: <h2>Finally Click to Get Report!🎉</h2>,
        locale: { skip: <strong>SKIP</strong> },
        target: "#getReportBtn",
      },
    ],
  });

  const categoryData = useCategoryDataQuery();
  const siteNameData = useSiteNameQuery();

  const selectedSiteData = useGetSelectedSiteDataQuery(siteId);
  console.log("selected sites", siteNameData);

  const handleSelectAllParameters = (value) => {
    const allParameters =
      filteredStationName[0]?.flatMap((item) => item.parameters || []) || [];

    if (value.includes("all")) {
      if (parameters.length === allParameters.length) {
        setParameters([]);
      } else {
        setParameters(allParameters);
      }
    } else {
      setParameters(value);
    }
  };

  useEffect(() => {
    if (userType) {
      // Set default category and site based on userType
      if (
        userType === "admin" ||
        userType === "vendor" ||
        userType === "cpcb"
      ) {
        setCategory(""); // Enable category selection for admin
        setSiteId(""); // Enable site selection for admin
      } else {
        setCategory("text");
        // setSiteId(userId);
        setSiteId(Number(userId)); // Replace 'userSiteId' with the actual site ID value for users
        const selectedSite = filteredSiteName.find(
          (item) => item.site_id === userId
        );
        if (selectedSite) {
          setSiteName(selectedSite.name);
        }
      }
    }
  }, [userType]);

  useEffect(() => {
    // Update the site names options when filteredSiteName changes
    if (filteredSiteName.length > 0) {
      const options = filteredSiteName.map((item) => ({
        value: item.site_id,
        label: item.name,
      }));
      setSiteNamesOptions(options);
    }
  }, []); //removed filteredSiteName

  useEffect(() => {
    const isFirstTimeLogin = !localStorage.getItem("loggedInBefore");

    if (isFirstTimeLogin) {
      localStorage.setItem("loggedInBefore", true);
      setFirstTimeLogin(true);
    }
  }, []);

  const fetchData = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + "bdRqZw97UrvVWe1eeUgfebeGlaWzVF",
    };

    setIsLoading(true); // Start loading
    setIsError(false); // Reset error state

    try {
      let url1, url2;

      // Define site and station conditions
      const specialSites = [
        1007, 1008, 1027, 1033, 1045, 1051, 1056, 1058, 1059, 1060, 1061, 1025,
        1062, 1063, 1011, 1064, 1039, 1003, 1036, 1010, 1009, 1011, 1032, 1012,
        1015, 1016, 1017, 1018, 1019, 1020, 1021, 1022, 1023, 1024, 1035, 1037,
        1041, 1046, 1049, 1050, 1055, 1065, 1057, 1042, 1006, 1028, 1030,  1040,
        1044, 1048, 1052, 1053, 1001, 1002, 1026, 1029, 1031, 1005,
      ]; // Add more siteIds as needed
      const specialStations = [
        "PCPL_Station_1",
        "EECP_Station_1",
        "VCL_Station_1",
        "ADC_Station_1",
        "SPSS_Station_1",
        "PHL_Station_1",
        "MHPL_Station_1",
        "NCT_Station_1",
        "PLB_Station_1",
        "HMG_Station_1",
        "LEP_Station_1",
        "SCIL_Station_1",
        "JSV_Station_1",
        "TVCA_Station_1",
        "PEPL_Station_1",
        "WEPL_Station_1",
        "JIL_Station_1",
        "MC_Station_1",
        "GTPL_Station_1",
        "PILCETP_Station_1",
        "DIECETP_Station_1",
        "PEPL_Station_1",
        "BVC_Station_1",
        "CHPL_Station_1",
        "BCSECFL_Station_1",
        "BCS_Station_1",
        "SVUM_Station_1",
        "KM_Station_1",
        "CRUP_Station_1",
        "JM_Station_1",
        "NMSTB_Station_1",
        "RUBC_Station_1",
        "NMSTFH_Station_1",
        "RUMC_Station_1",
        "SKMPL_Station_1",
        "DPMPL_Station_1",
        "CRSC_Station_1",
        "SSPL_Station_1",
        "DCBL_Station_1",
        "SAEPL_Station_1",
        "MVCIP_Station_1",
        "BLW_Station_1",
        "MCI_Station_1",
        "GIL_Station_1",
        "SP_Station_1",
        "MEC_Station_1",
        "EP_Station_1",
        "MSC_Station_1",
        "SIL_Station_1",
        "GM_Station_1",
        "MGIPL_Station_1",
        "YBL_Station_1",
        "IAKO_Station_1",
        "DSH_Station_1",
        "PPL_Station_1",
        "INAMB_Station_1",
        "INAME_Station_1",
        "MS_Station_1",
        "MS_Station_2",
      ]; // Add more stations as needed
      console.log("sepcial sites", specialSites);

      if (specialSites.includes(siteId) && specialStations.includes(station)) {
        console.log("user", typeof siteId);
        // For special sites and stations, use url1 for both average and median data
        url1 = `http://envicrafts.com:8080/data/average/?from=${startDate}&to=${endDate}&site_id=${siteId}&station=${station}&interval=${timeInterval}${parameters
          .map((item) => `&parameters=${item}`)
          .join("")}`;

        const response1 = await axios.get(url1, { headers });

        const resultTable = response1.data.result_table;
        const statistics = response1.data.statistics;

        setAverageReport(resultTable);
        setMedianReport(statistics);
      } else {
        // For other sites and stations
        url1 = `http://envicrafts.com:8080/data/average/?from=${startDate}&to=${endDate}&site_id=${siteId}&station=${station}&interval=${timeInterval}${parameters
          .map((item) => `&parameters=${item}`)
          .join("")}`;
        url2 = `http://envicrafts.com:8080/data/statistics/?from=${startDate}&to=${endDate}&site_id=${siteId}&station=${station}&interval=${timeInterval}${parameters
          .map((item) => `&parameters=${item}`)
          .join("")}`;

        console.log("admin", typeof siteId);

        // Fetch both the average (result_table) and statistics data
        const [response1, response2] = await Promise.all([
          axios.get(url1, { headers }),
          axios.get(url2, { headers }),
        ]);

        setAverageReport(response1.data);
        setMedianReport(response2.data);
      }
    } catch (error) {
      console.error("Error fetching report data:", error);
      setIsError(true);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  // Validating Input Fields
  const handleButtonClick = () => {
    if (
      category &&
      siteId &&
      station &&
      parameters.length > 0 &&
      timeInterval &&
      startDate &&
      endDate
    ) {
      fetchData();
      setIsOpen(true);
    } else {
      // Display an error message or handle the incomplete form submission as desired
      setIsError(true);
    }
  };

  // const filteredSiteName = siteNameData?.data?.filter(
  //   (item) => item.category === category
  // ) || [];

  const vendorName = localStorage.getItem("VendorName");

  const filteredSiteName =
    siteNameData?.data?.filter((item) => {
      if (userType === "cpcb") {
        return item.category === category && item["CPCB Status"] === true;
      } else if (userType === "vendor") {
        return item.category === category && item["vendor"] === vendorName;
      }
      return item.category === category;
    }) || [];

  console.log("filee", filteredSiteName);

  // const vendorfilteredSiteName = VendorsiteNameData?.data?.filter(
  //   (item) => item.category === category
  // ) || [];

  const UserSiteName = siteNameData?.data?.filter((item) => item.name) || [];

  const filteredStationName = selectedSiteData?.data?.map((item) => {
    return item.stations.filter((name) => name.station === station);
  });

  const onDateChange = (dates, dateStrings) => {
    const currentDate = new Date();
    const selectedEndDate = new Date(dateStrings[1]);
    if (selectedEndDate > currentDate) {
      setDateSelectionError(true);
    } else {
      setStartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);
      setDateSelectionError(false);
    }
  };

  const disableFutureMonthArrow = (current) => {
    const today = moment(); // Use moment() to get today's date
    return current && current.month() > today.month(); // Disable months ahead of the current month
  };

  if (categoryData.isLoading) return <Loader />;
  if (siteNameData.isLoading) return <Loader />;
  if (selectedSiteData.isLoading) return <Loader />;
  if (isLoading) return <ReportBuildLoader />;

  return (
    <div className="generatereport">
      {firstTimeLogin ? (
        <Joyride
          continuous
          callback={() => {}}
          run={run}
          steps={steps}
          hideCloseButton
          scrollToFirstStep
          showSkipButton
        />
      ) : null}
      <h1>Generate Report</h1>
      <div className="generatereport__userNote">
        <GoNote className="generatereport__userNote__icon" />
        <p>
          Please select appropriate values in the dropdown menus below. If you
          would like to view the report graphically, please check the "get Graph
          view" checkbox. Please note that we cannot provide data reports for
          periods more than six months in the past. If you require data from
          further back than six months, please contact TechniCrafts for
          assistance.
        </p>
        {isError ? (
          <div className="generatereport__filter__errorMsg">
            <MdOutlineErrorOutline className="generatereport__filter__errorMsg__icon" />
            Please fill in all the input fields properly before generating the
            report.
          </div>
        ) : null}
      </div>

      <div className="generatereport__filter__wrapper">
        {userType === "operator"
          ? null
          : (userType === "admin" ||
              userType === "vendor" ||
              userType === "cpcb") && (
              <div className="generatereport__filter" id="category">
                <span>Select Site Category</span>
                <Select
                  className="generatereport__filter__dropdown"
                  placeholder="Select Category"
                  size="large"
                  value={category}
                  onChange={(value) => {
                    setCategory(value);
                    setSiteId(null);
                    setStation(null);
                    setParameters([]);
                  }}
                >
                  {categoryData.data.map((item) => (
                    <Option key={item.id} value={item.category}>
                      {item.category}
                    </Option>
                  ))}
                </Select>
              </div>
            )}

        {userType === "operator"
          ? null
          : (userType === "admin" ||
              userType === "vendor" ||
              userType === "cpcb") && (
              <div className="generatereport__filter" id="siteName">
                <span>Select Site Name</span>
                <Select
                  className="generatereport__filter__dropdown"
                  size="large"
                  placeholder="Select Site Name"
                  value={siteId || undefined}
                  onChange={(value) => {
                    setSiteId(value);
                    setStation(null);
                    setParameters([]);
                  }}
                >
                  {filteredSiteName.map((item) => (
                    <Option key={item.site_id} value={item.site_id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
            )}

        <div className="generatereport__filter" id="stationName">
          <span>Select Station Name</span>
          <Select
            className="generatereport__filter__dropdown"
            size="large"
            placeholder="Select Station"
            value={station}
            onChange={(value) => setStation(value)}
            disabled={!siteId}
          >
            {selectedSiteData?.data?.map((item) => {
              console.log(selectedSiteData);

              return item.stations.map((name) => {
                return <Option value={name.station}>{name.station}</Option>;
              });
            })}
          </Select>
        </div>
        <div className="generatereport__filter" id="parameters">
          <span>Select Parameters</span>
          <Select
            mode="multiple"
            className="generatereport__filter__dropdown"
            size="large"
            value={parameters}
            onChange={handleSelectAllParameters}
          >
            <Option value="all"> Select All</Option>
            {filteredStationName[0]?.map((item) => {
              return item.parameters?.map((names, id) => {
                return (
                  <Option key={id} value={names}>
                    {names}
                  </Option>
                );
              });
            })}
          </Select>
        </div>
        <div className="generatereport__filter" id="timeInterval">
          <span>Select Time Interval</span>
          <Select
            className="generatereport__filter__dropdown"
            size="large"
            value={timeInterval}
            onChange={(value) => setTimeInterval(value)}
          >
            <Option value={1}>1 Minute</Option>
            <Option value={15}>15 Minutes</Option>
            <Option value={30}>30 Minutes</Option>
          </Select>
        </div>
        <div className="generatereport__filter" id="dateRange">
          {dateSelectionError ? (
            <span style={{ color: "red" }}>Please Select Valid Date</span>
          ) : (
            <span>Select Start and End Date</span>
          )}
          <RangePicker
            aria-required
            className="generatereport__filter__dropdown"
            size="large"
            onChange={onDateChange}
            disabledDate={(current) =>
              current && current.isAfter(moment().endOf("day"))
            } // Use moment() to disable future dates
            disabledMonth={disableFutureMonthArrow} // Disable month navigation beyond the current month
          />
        </div>
        <div className="generatereport__filter" id="graphCheckbox">
          <Checkbox
            className="generatereport__filter__checkbox"
            onClick={() => setIsChecked(true)}
          >
            Get Graph View
          </Checkbox>
        </div>
        <div className="generatereport__filter" id="getReportBtn">
          <button
            className="generatereport__filter__getReport__btn"
            onClick={handleButtonClick}
          >
            Get Report
          </button>
        </div>
      </div>

      {isOpen && averageReport && averageReport.length === 0 ? (
        <div className="filteredreport__popup">
          <button
            className="filteredreport__popup__header__btn"
            onClick={() => setIsOpen(false)}
          >
            <MdKeyboardArrowLeft className="filteredreport__popup__header__btn__icon" />{" "}
            Back to Report Generation
          </button>
          <div className="filteredreport__popup__error__container">
            <TbReportOff className="filteredreport__popup__header__content__icon" />
            <h1> Sorry , No Data Available</h1>
            <p>There is no data available for the selected criteria.</p>
          </div>
        </div>
      ) : (
        isOpen &&
        averageReport &&
        medianReport && (
          <div className="filteredreport__popup">
            <div className="filteredreport__popup__header">
              <div className="filteredreport__popup__header__content__wrapper">
                <AiOutlineFileDone className="filteredreport__popup__header__content__icon" />
                <div className="filteredreport__popup__header__content">
                  <h1>Your Report is Ready!</h1>
                  <p>
                    Export your data effortlessly in PDF and Excel formats for
                    seamless sharing and analysis.
                  </p>
                  <p>
                    (Please scroll down to view the report presented in a
                    graphical format.)
                  </p>
                </div>
              </div>
              <button
                className="filteredreport__popup__header__btn"
                onClick={() => setIsOpen(false)}
              >
                <MdKeyboardArrowLeft className="filteredreport__popup__header__btn__icon" />{" "}
                Back to Report Generation
              </button>
            </div>

            <CombinedReportTable
              medianReport={medianReport}
              averageReport={averageReport}
              startDate={startDate}
              endDate={endDate}
              station={station}
              siteName={
                UserSiteName.find((item) => item.site_id === siteId)?.name
              }
              // siteName={filteredSiteName.find((item) => item.site_id === siteId)?.name}
            />

            <MedianReportDataTable
              medianReport={medianReport}
              startDate={startDate}
              endDate={endDate}
              station={station}
            />

            <FilteredReportTable
              averageReport={averageReport}
              startDate={startDate}
              endDate={endDate}
              station={station}
            />

            {isChecked && (
              <FilteredReportGraph
                averageReport={averageReport}
                medianReport={medianReport}
                startDate={startDate}
                endDate={endDate}
                station={station}
                siteName={
                  UserSiteName.find((item) => item.site_id === siteId)?.name
                }
                // siteName={filteredSiteName.find((item) => item.site_id === siteId)?.name}
              />
            )}
          </div>
        )
      )}
    </div>
  );
};

export default AverageGenerateReport;

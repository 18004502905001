
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CircleIcon from "@mui/icons-material/Circle";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import "../overallsummary.css";
import { useGetSitesDataQuery } from "../../../Store/Slices/overallSummarySlice";
import Loader from "../../../global/Loader/Loader";
import "./PieChart.css";


const columns = [
  {
    field: "name",
    headerName: "Site Name",
    width: 730,
    renderHeader: () => (
      <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
        Site Name
        <CircleIcon style={{ color: "red", fontSize: "14px", marginLeft:"20px" }} />
        <span style={{ color: "red", fontSize: "14px" }}>expired</span>
      </div>
    ),
  },
  {
    field: "today_exceedance",
    headerName: "Today Exceedance",
    width: 200,
  },
];

export default function SiteDataGrid() {
  const [siteData, setSiteData] = useState([]);
  const res = useGetSitesDataQuery();

  useEffect(() => {
    if (res.isSuccess) {
      // Filter sites where CPCB Status is true
      const filteredData = res.data.filter(site => site["CPCB Status"] === true);
      setSiteData(filteredData);
    }
  }, [res]);

  if (res.isLoading) return <Loader />;

  
  const today = new Date();

  // Function to get row class name based on expiry date
  const getRowClassName = (params) => {
    const expiryDate = new Date(
      params.row.stations[0]?.expiry_date || today // Default to today if no expiry_date
    );
    return expiryDate < today ? "expired-row" : "";
  };


  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={siteData}
        getRowId={(row) => row.site_id}
        columns={columns}
        rowsPerPageOptions={[100]}
        experimentalFeatures={{ newEditingApi: true }}
        className="overallsummary__sitesdatatable"
        getRowClassName={getRowClassName}
      />
    </Box>
  );
}

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HEADERS, BASE_URL } from "../../api";

export const overallSummaryApi = createApi({
  reducerPath: "overallSummary",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),



  endpoints: (builder) => ({
    getSiteStatus: builder.query({
      query: () => ({
        url: "sites/overall_sites/",
        method: "GET",
        headers: HEADERS,
      }),
    }),


    getCpcbSiteStatus: builder.query({
      query: () => ({
        url: "sites/cpcboverall_sites/",
        method: "GET",
        headers: HEADERS,
      }),
    }),


    getVendorSitesStatus: builder.query({
      query: () => {
        const VendorName = localStorage.getItem("VendorName"); // Retrieve VendorName dynamically
        return {
          url: `sites/vendoroverall_sites/?Vendor_Name=${VendorName}`,
          method: "GET",
          headers: HEADERS,
        };
      },
    }),

   

    getSitesData: builder.query({
      query: () => ({
        url: "/sites/",
        method: "GET",
        headers: HEADERS,
      }),
    }),

    getVendorSitesData: builder.query({
      query: () => {
         const VendorName = localStorage.getItem("VendorName"); // Retrieve VendorName from localStorage
        return {
          url: `/sites/?Vendor_Name=${VendorName}`,
          method: "GET",
          headers: HEADERS,
        };
      },
    }),
    

    getPastWeekSiteStatus: builder.query({
      query: () => ({
        url: "sites/site-status/",
        method: "GET",
        headers: HEADERS,
      }),
    }),
  }),
});


export const {
  useGetSiteStatusQuery,
  useGetCpcbSiteStatusQuery,
  useGetVendorSitesStatusQuery,
  useGetSitesDataQuery,
  useGetVendorSitesDataQuery,
  useGetPastWeekSiteStatusQuery,
} = overallSummaryApi;

import React from "react";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const CombinedReportTable = ({
  medianReport,
  averageReport,
  startDate,
  endDate,
  station,
  siteName,
}) => {

  console.log("sitename :-", siteName);
  
  // Export to Excel
  const exportToExcel = () => {
    // Check if reports have data
    if (!medianReport || !averageReport) {
      alert("No data to export.");
      return;
    }

    const workbook = XLSX.utils.book_new();
    const currentDate = new Date().toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });

    // Header information
    const headerInfo = [
      ["TechniCrafts Soft Solutions LLP"],
      ["Online Data Acquisition System"],
      [`Report Generated for ${siteName} and Station ${station}`],
      [`Date Range: ${startDate} to ${endDate}`],
      [`Report Generated Time: ${currentDate}`],
      [],
    ];

    // Convert median report data to array for Excel
    const medianTableData = [["Parameter", "Average", "Minimum", "Maximum", "Median", "Mode", "Deviation"],];
    if (medianReport && Object.keys(medianReport).length > 0) {
      Object.entries(medianReport[0]).forEach(([parameter, values]) => {
        medianTableData.push([
          parameter,
          values?.average?.toString() ||  "",
          values?.min?.toString() ||  "",
          values?.max?.toString() ||  "",
          values?.median?.toString() ||  "",
          values?.mode?.toString() ||  "",
          values?.deviation?.toString() ||  "",
        ]);
      });
    }

    // Convert average report data to array for Excel
    const averageTableData = [];
    const averageReportHeaders = Object.keys(averageReport[0] || {}).map((key) =>
      key === "transaction_at" ? "Timestamp" : key
    );
    averageTableData.push(averageReportHeaders);

    averageReport.forEach((row) => {
      const rowData = averageReportHeaders.map((key) => row[key]?.toString() || "");
      averageTableData.push(rowData);
    });

    // Combine header information, Median Report, and Average Report into a single array
    const combinedData = [
      ...headerInfo,
      ["Median Report"],
      ...medianTableData,
      [],
      ["Average Report"],
      ...averageTableData,
    ];

    // Convert combined data to worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(combinedData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Combined Report");

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, `${siteName}_.xlsx`);
  };

  // Export to PDF
  const exportToPDF = () => {
    // Check if reports have data
    if (!medianReport || !averageReport) {
      alert("No data to export.");
      return;
    }

    const doc = new jsPDF();
    const currentTime = new Date().toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });

    const companyName = "TechniCrafts Soft Solutions LLP";
    const additionalContent = `Online Data Acquisition System`;
    const siteNameContent = `Report Generated for ${siteName} and Station ${station}`;
    const dateRangeContent = `Date Range: ${startDate} to ${endDate}`;
    const generatedTimeContent = `Report Generated Time: ${currentTime}`;

    doc.setFontSize(18);
    doc.setTextColor(128, 128, 128);
    doc.text(additionalContent, doc.internal.pageSize.getWidth() / 2, 22, {
      align: "center",
    });

    doc.setFontSize(10);
    doc.text(siteNameContent, doc.internal.pageSize.getWidth() / 2, 28, {
      align: "center",
    });
    doc.text(dateRangeContent, doc.internal.pageSize.getWidth() / 2, 34, {
      align: "center",
    });
    doc.text(generatedTimeContent, doc.internal.pageSize.getWidth() / 2, 40, {
      align: "center",
    });

    // Add Median Report table
    const medianTableData = Object.entries(medianReport[0]).map(([parameter, values]) => [
      parameter,
      values.average,
      values.min,
      values.max,
      values.median,
      values.mode,
      values.deviation,
    ]);

    doc.autoTable({
      head: [
        [
          "Parameter",
          "Average",
          "Minimum",
          "Maximum",
          "Median",
          "Mode",
          "Deviation",
        ],
      ],
      body: medianTableData,
      startY: 50,
    });

    // Add Average Report table
    const averageTableData = averageReport.map((row) =>
      Object.keys(row).map((key) =>
        key === "transaction_at" ? row[key] || "" : row[key]?.toString() || ""
      )
    );
    const averageReportHeaders = Object.keys(averageReport[0] || {}).map((key) =>
      key === "transaction_at" ? "Timestamp" : key
    );

    doc.autoTable({
      head: [averageReportHeaders],
      body: averageTableData,
      startY: doc.autoTable.previous.finalY + 10,
    });

    // Add footer
    for (let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
      doc.setPage(i);
      const pageHeight = doc.internal.pageSize.getHeight();
      const pageWidth = doc.internal.pageSize.getWidth();
      doc.setFontSize(10);
      doc.setTextColor(128, 128, 128);
      doc.setFont("helvetica", "italic");
      doc.text(companyName, pageWidth / 2, pageHeight - 10, {
        align: "center",
      });
    }

    doc.save(`${siteName}_.pdf`);
  };

  return (
    <div className="combined-report">
      <div className="combined-report__export-btns-wrapper">
        <button
          className="filteredreport__table__export__btns"
          onClick={exportToExcel}
        >
          Export to Excel
        </button>
        <button
          className="filteredreport__table__export__btns"
          onClick={exportToPDF}
        >
          Export to PDF
        </button>
      </div>
    </div>
  );
};

export default CombinedReportTable;

// import React, { useEffect, useRef } from "react";
// import "./CameraStatus.css";

// const CameraStream = () => {
//   const videoRef = useRef(null);

  // useEffect(() => {
  //   // Access the user's camera
  //   if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
  //     navigator.mediaDevices
  //       .getUserMedia({ video: true })
  //       .then((stream) => {
  //         videoRef.current.srcObject = stream;
  //         videoRef.current.play();
  //       })
  //       .catch((error) => {
  //         console.error("Error accessing camera:", error);
  //       });
  //   }
  // }, []);

//   return (

//     <div className="camera-stream">
//       <h1 className="heading">Live Camera Stream</h1>
//       <video className="video"></video>
//       <p className="description">Enjoy the real-time camera feed!</p>
//     </div>

//   );
// };

// export default CameraStream;



import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid"; // Assuming you're using MUI DataGrid
import "./VendorCameraStatus.css";
import {Link} from "react-router-dom"
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import { IoEyeSharp } from "react-icons/io5";
import Loader from "../../global/Loader/Loader";

const VendorCameraStream = () => {

  const [data, setData] = useState([]);

  const apiKey = process.env.REACT_APP_API_KEY;
  const VendorName = localStorage.getItem("VendorName");

  useEffect(() => {

    const fetchData = async () => {

      try {

        const response = await fetch(`http://envicrafts.com:8080/sites/?Vendor_Name=${VendorName}`, {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
       
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();

        // Log the data to the console
        console.log("vendor", result);

        const dataWithId = result.map((row) => ({
          ...row,
          id: row.site_id.toString(),
        }));

        setData(dataWithId);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };  

    fetchData();
  }, [apiKey]);


  const columns = [
    { field: "site_id", headerName: "ID", width: 70 },
    {
      field: "camera",
      headerName: "Camera",
      width: 80,
      renderCell: (params) => (
        <Link to={{
          pathname: `/vendor/camerapage/${params.row.site_id}`,
          state: { siteData: params.row },
        }}>
           <VideoCameraFrontIcon  className="videocamericon" />
        </Link>
      ),
    },
    { field: "name", headerName: "Name", width: 250 },
    { field: "label", headerName: "Label", width: 150 },
    { field: "category", headerName: "Category", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "authority", headerName: "Authority", width: 150 },
    { field: "state", headerName: "State", width: 150 },
    { field: "city", headerName: "City", width: 150 },
    { field: "today_exceedance", headerName: "Today Exceedance", width: 200 },
    { field: "address", headerName: "Address", width: 200 },
    { field: "latitude", headerName: "Latitude", width: 150 },
    { field: "longitude", headerName: "Longitude", width: 150 },
    {
      field: "stations",
      headerName: "Stations",
      width: 250,
      valueGetter: (params) =>
        params.row.stations
          ? params.row.stations.map((s) => s.station).join(",")
          : "",
    },
    {
      field: "parameters",
      headerName: "Parameters",
      width: 450,
      valueGetter: (params) =>
        params.row.stations.map((value) =>
          value.parameters.map((item) => item).join(", ")
        ),
     },
  ];

  return (
    <div className="camera-stream">
      {data.length > 0 ? (
        <DataGrid
        className="data-grid-table"
          rows={data}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[10, 25, 50, 100]}
          pagination
        />
      ) : (
        <p>
          <Loader />
        </p>
      )}
    </div>
  );
};

export default VendorCameraStream;

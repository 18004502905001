import { useState } from "react";
import { Select, Space, TimePicker } from "antd";
import "./Calibration.css";
import { HiOutlineUpload } from "react-icons/hi";
import { BsArrowLeftShort } from "react-icons/bs";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


  const RemoteCalibration = ({ setHandlePopup, setOpen }) => {
  const [eventName, setEventName] = useState();
  const [siteName, setSiteName] = useState();
  const [workCategory, setWorkCategory] = useState();
  const [MonitorType, setMonitorType] = useState();
  const [monitorStation, setMonitorStation]= useState();
  const [parameter, setParameter] = useState();
  const [assignedTo, setAssignedTo] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startDateTime, setStartDateTime] = useState(new Date());
  const [endDateTime, setEndDateTime] = useState(new Date());
  const [externalCalibration, setexternalCalibration] = useState(null);
  const [calibrationType, setCalibrationType] = useState(null);


  const handleRadioChange = (event) => {
    setexternalCalibration(event.target.value);
  };

  const handleCalibrationType = (event) => {
    setCalibrationType(event.target.value);
  };

  const handleStartDateTimeChange = (newDate) => {
    setStartDateTime(newDate);
  };

  const handleEndDateTimeChange = (newDate) => {
    setEndDateTime(newDate);
  };

  return (
    <div className="RemoteCalibration">
      <div className="remotecal__header">
        <h1>Remote Calibration</h1>

        <button
          onClick={() => {
            setHandlePopup(false);
          }}
        >
          <BsArrowLeftShort className="remotecal__header__btn__icon" /> back
        </button>
      </div>


      <label className="remotecal-label">Remote Calibration Name  </label>
      <input
          value={eventName}
          size="large"
          className="remotecal-input"
          onChange={(e) => setEventName(e.target.value)}
          placeholder="Enter Remote Calibration Name"
        />


      <div className="remotecal__popup__content">

        <Select
          defaultValue="Site Name"
          style={{
            width: "100%",
            fontSize: "2rem",
          }}
          value={siteName}
          size="large"
          className="dropdown"
          onChange={(value) => setSiteName(value)}
          options={[
            {
              value: "jack",
              label: "Jack",
            },
            {
              value: "lucy",
              label: "Lucy",
            },
            {
              value: "Yiminghe",
              label: "yiminghe",
            },
          ]}
        />


        <Select
          defaultValue="Monitoring Type"
          style={{
            width: "100%",
            fontSize: "2rem",
          }}
          value={MonitorType}
          size="large"
          className="dropdown"
          onChange={(value) => setAssignedTo(value)}
          options={[
            {
              value: "Effluent",
              label: "Effluent",
            },
            {
              value: "All",
              label: "All",
            },
          ]}
        />


        <Select
          defaultValue="Monitoring Unit"
          style={{
            width: "100%",
            fontSize: "2rem",
          }}
          value={workCategory}
          size="large"
          className="dropdown"
          onChange={(value) => setWorkCategory(value)}
          options={[
            {
              value: "Analyser Maintenance",
              label: "Analyser Maintenance",
            },
            {
              value: "Analyser Calibration",
              label: "Analyser Calibration",
            },
          
          ]}
        />

       


        <Select
          defaultValue="Analyzer"
          style={{
            width: "100%",
            fontSize: "2rem",
          }}
          value={monitorStation}
          size="large"
          className="dropdown"
          onChange={(value) => setMonitorStation(value)}
          options={[
            {
              value: "In Progress",
              label: "In Progress",
            },
            {
              value: "Incomplete",
              label: "Incomplete",
            },
            {
              value: "Completed ",
              label: "Completed",
            },
          ]}
        />


        <Select
          defaultValue="Parameter"
          style={{
            width: "100%",
            fontSize: "2rem",
          }}
          value={parameter}
          size="large"
          className="dropdown"
          onChange={(value) => setParameter(value)}
          options={[
            {
              value: "In Progress",
              label: "In Progress",
            },
            {
              value: "Incomplete",
              label: "Incomplete",
            },
            {
              value: "Completed ",
              label: "Completed",
            },
          ]}
        />

        {/* <RangePicker
          className="generatereport__datePicker"
          onChange={onDateChange}
          size="large"
        />
        <TimePicker.RangePicker size="large" /> */}

        <Select
          defaultValue="Calibrator"
          style={{
            width: "100%",
            fontSize: "2rem",
          }}
          // value={workCategory}
          size="large"
          className="dropdown"
          // onChange={(value) => setAssignedTo(value)}
          options={[
            {
              value: "All",
              label: "All",
            },
          ]}
        />



        <Select
          defaultValue="Channel"
          style={{
            width: "100%",
            fontSize: "2rem",
          }}
          // value={workCategory}
          size="large"
          className="dropdown"
          // onChange={(value) => setAssignedTo(value)}
          options={[
            {
              value: "All",
              label: "All",
            },
          ]}
        />


       <Select
          defaultValue="Assigned To"
          style={{
            width: "100%",
            fontSize: "2rem",
          }}
          // value={workCategory}
          size="large"
          className="dropdown assignedfake"
          // onChange={(value) => setAssignedTo(value)}
          options={[
            {
              value: "All",
              label: "All",
            },
          ]}
        />


    
      <div className="calibration-radio">
        <div className="calibration-label" >  External Calibration:</div>
        <label className="externalcal-radio" >
          <input
            type="radio"
            value="yes"
            checked={externalCalibration === 'yes'}
            onChange={handleRadioChange}
            className="externalcal-input"
          />
          Yes
        </label>
        <label className="externalcal-radio">
          <input
            type="radio"
            value="no"
            checked={externalCalibration === 'no'}
            onChange={handleRadioChange}
            className="externalcal-input"
          />
          No
        </label>
      </div>
   




      
      <div className="calibration-radio">
        <div className="calibration-label">Calibration Type:</div>
        <label className="externalcal-radio" >
          <input
            type="radio"
            value="Immediate"
            checked={calibrationType === 'Immediate'}
            onChange={handleCalibrationType}
            className="externalcal-input"
          />
          Immediate
        </label>
        <label className="externalcal-radio">
          <input
            type="radio"
            value="Scheduled"
            checked={calibrationType === 'Scheduled'}
            onChange={handleCalibrationType}
            className="externalcal-input"
          />
          Scheduled
        </label>
      </div>




      <Select
          defaultValue="Assigned To"
          style={{
            width: "100%",
            fontSize: "2rem",
          }}
          size="large"
          className="dropdown assignedfake"
          options={[
            {
              value: "All",
              label: "All",
            },
          ]}
        />


<Select
          defaultValue="Assigned To"
          style={{
            width: "100%",
            fontSize: "2rem",
          }}
          size="large"
          className="dropdown assignedfake"
          options={[
            {
              value: "All",
              label: "All",
            },
          ]}
        />

    


      <div className="calibration-datetime">
      <label>Start Date:</label>
      <DatePicker selected={startDate}
       onChange={(date) => setStartDate(date)}
       dateFormat="Pp"
       className="start-date-input"
       showTimeSelect
      />
      </div>
 
    <div className="calibration-datetime">
      <label>End Date:</label>
      <DatePicker selected={endDate}
       onChange={(date) => setEndDate(date)} 
        showTimeSelect
        className="end-date-input"
         dateFormat="Pp"
         />
    </div>

      <div className="calibration-datetime">
      <label>Schedule Time</label>
<DatePicker
      selected={startDate}
        onChange={(date) => setStartDate(date)}
       className="time-to-respond"
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
      />
       </div>

      <div className="calibration-datetime">
      <label>Schedule</label>
       <Select className="schedule-day" defaultValue="Daily">
        <options value="Daily">Daily</options>
        <options value="Weekly">Weekly</options>
        <options value="Monthly">Monthly</options>
        <options value="Quarterly">Quarterly</options>

       </Select>
       </div>

       </div>
       <textarea type="text" placeholder="Enter work discription" />
       <button
        className="remotecal__popup__submitBtn"
        onClick={() => {
          setHandlePopup(false);
          setOpen(true);
        }}
       >
        Save{" "}
        <HiOutlineUpload className="remotecal__popup__submitBtn__icon" />
        </button>
    </div>
  );
};

export default RemoteCalibration;



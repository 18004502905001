import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HEADERS, BASE_URL } from "../../api";

export const vendorOverallSummaryApi = createApi({
  reducerPath: "vendorOverallSummary",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),

  endpoints: (builder) => ({
    getVendorSiteStatus: builder.query({
      query: () => ({
        url: "sites/overall_sites/",
        method: "GET",
        headers: HEADERS,
      }),
    }),

    getVendorSitesData: builder.query({
      query: (VendorName) => ({
        url: `/sites/?Vendor_Name=${VendorName}`,
        method: "GET",
        headers: HEADERS,
      }),
    }),

    getVendorPastWeekSiteStatus: builder.query({
      query: () => ({
        url: "sites/site-status/",
        method: "GET",
        headers: HEADERS,
      }),
    }),
  }),
});


export const {
  useGetVendorSiteStatusQuery,
  useGetVendorSitesDataQuery,
  useGetVendorPastWeekSiteStatusQuery,
} = vendorOverallSummaryApi;

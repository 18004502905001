


import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  useGetSiteSummaryDataQuery,
  useGetCurrentSiteDataQuery,
  useGetVendorCurrentSiteDataQuery,
} from "../../Store/Slices/siteSummarySlice";
import CircleIcon from "@mui/icons-material/Circle";
import Loader from "../../global/Loader/Loader";
import UserSubscription from "../../User/UserSubscription/UserSubscription";
import "./StationStatus.css"; // Import custom CSS for styling

export default function StationStatus({ siteId, vendorName }) {
  const userType = localStorage.getItem("role");

  // Fetch data for admin, vendor, and other user types
  const res = useGetSiteSummaryDataQuery(); // Admin and cpcb data
  const ven = useGetVendorCurrentSiteDataQuery(vendorName); // Vendor data
  const data = useGetCurrentSiteDataQuery(siteId); // Specific site data

  const isAdmin = userType === "admin";
  const isVendor = userType === "vendor";
  const isCpcb = userType === "cpcb";

  const columns = [
    { field: "site_id", headerName: "ID", width: 80 },
    {
      field: "stations",
      headerName: "Stations",
      width: 300,
      valueGetter: (params) =>
        params.row.stations
          ? params.row.stations.map((s) => s.station).join(", ")
          : "",
    },
    { field: "name", headerName: "Name", width: 300 },
    isAdmin && {
      field: "Topic",
      headerName: "Topic",
      width: 150,
      valueGetter: (params) =>
        params.row.stations.map((value) => value.Topic).join(", "),
    },

    isAdmin && {
      field: "vendor",
      headerName: "Vendor",
      width: 150,
    },
    
    {
      field: "monitoring_type",
      headerName: "Monitoring Type",
      width: 150,
      valueGetter: (params) =>
        params.row.stations.map((value) => value.monitoring_type).join(", "),
    },
    {
      field: "make",
      headerName: "Make",
      width: 250,
      valueGetter: (params) =>
        params.row.stations.map((value) => value.make).join(", "),
    },
    {
      field: "model",
      headerName: "Model",
      width: 150,
      valueGetter: (params) =>
        params.row.stations.map((value) => value.model).join(", "),
    },
    {
      field: "configured_date",
      headerName: "Configured Date",
      width: 200,
      valueGetter: (params) =>
        params.row.stations.map((value) => value.configured_date).join(", "),
    },
    {
      field: "expiry_date",
      headerName: "Expiry Date",
      width: 200,
      valueGetter: (params) =>
        params.row.stations.map((value) => value.expiry_date).join(", "),
    },
  ];

  if (res.isLoading || ven.isLoading || data.isLoading) return <Loader />;

  const rows = isAdmin
    ? res.data || [] // Admin gets all data
    : isVendor
    ? ven.data || [] // Vendor gets filtered data
    : isCpcb
    ? (res.data || []).filter((item) => item["CPCB Status"] === true) // CPCB users get filtered data
    : data.data || []; // Other users get specific site data

  const today = new Date();

  const getRowClassName = (params) => {
    const expiryDate = new Date(
      params.row.stations[0]?.expiry_date || today // Default to today if no expiry_date
    );
    return expiryDate < today ? "expired-row" : "";
  };

  return (
    <div
      style={{
        padding: "5rem 2rem 2rem 2rem",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <h1>Station Status</h1>

      {!isAdmin && <UserSubscription />}

      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.site_id}
        rowsPerPageOptions={[100]}
        experimentalFeatures={{ newEditingApi: true }}
        className="overallsummary__sitesdatatable"
        components={{
          Toolbar: GridToolbar,
        }}
        getRowClassName={getRowClassName}
      />
    </div>
  );
}

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { overallSummaryApi } from "../Store/Slices/overallSummarySlice";
import { vendorOverallSummaryApi } from "../Store/Slices/vendorOverallSummarySlice";
import { siteSummaryApi } from "./Slices/siteSummarySlice";
import { generateReportApi } from "./Slices/generateReportSlice";
import { noficationsApi } from "./Slices/notificationSlice";
import { calibrationApi } from "./Slices/calibrationSlice";
import cpcbReportApi from "./Slices/cpcbReportSlice";

export const store = configureStore({

  reducer: {
    [overallSummaryApi.reducerPath]: overallSummaryApi.reducer,
    [vendorOverallSummaryApi.reducerPath]: vendorOverallSummaryApi.reducer,
    [siteSummaryApi.reducerPath]: siteSummaryApi.reducer,
    [generateReportApi.reducerPath]: generateReportApi.reducer,
    [noficationsApi.reducerPath]: noficationsApi.reducer,
    [calibrationApi.reducerPath]: calibrationApi.reducer,
    [cpcbReportApi.reducerPath]: cpcbReportApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(siteSummaryApi.middleware, calibrationApi.middleware,cpcbReportApi.middleware)
});

setupListeners(store.dispatch);


import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "./VendorCameraStatus.css";
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftShort } from 'react-icons/bs';
import VendorCameraFrame from './VendorCameraFrame';
import VendorCameraHistory from './VendorCameraHistory';

const VendorCameraPage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();

  const handleTabChange = (index) => {
    setTabIndex(index);
  };

  const back=()=>{
    navigate("/vendor/camerastream")
  }

  return (
    <Tabs selectedIndex={tabIndex} onSelect={handleTabChange} className="camerapage">
      <TabList>
        <Tab>Camera Frame</Tab>
        <Tab>Camera History</Tab>
        <button className='camerabackbtn' onClick={back}>
        <BsArrowLeftShort className='backbtnicon' />Back</button>
      </TabList>

      <TabPanel>
        <VendorCameraFrame />
      </TabPanel>
      
      <TabPanel>
        <VendorCameraHistory />
      </TabPanel>

    </Tabs>
  );
};

export default VendorCameraPage;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HEADERS, BASE_URL } from "../../api";

export const siteSummaryApi = createApi({
  reducerPath: "siteSummaryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  
  endpoints: (builder) => ({
    getSiteSummaryData: builder.query({
      query: () => ({
        url: "sites/",
        method: "GET",
        headers: HEADERS,
      }),
    }),

    getCurrentSiteData: builder.query({
      query: (id) => ({
        url: `sites/?site_id=${id}`,
        method: "GET",
        headers: HEADERS,        
      }),
    }),


    getCpcbCurrentSiteData: builder.query({
      query: () => {
        const VendorName = localStorage.getItem("VendorName"); // Retrieve VendorName from localStorage
        return {
          url: `/sites/?Vendor_Name=${VendorName}`,
          method: "GET",
          headers: HEADERS,
        };
      },
    }),



    getVendorCurrentSiteData: builder.query({
      query: () => {
        const VendorName = localStorage.getItem("VendorName"); // Retrieve VendorName from localStorage
        return {
          url: `/sites/?Vendor_Name=${VendorName}`,
          method: "GET",
          headers: HEADERS,
        };
      },
    }),

    getCurrentSiteParameterData: builder.query({
      query: (id) => {
        const role = localStorage.getItem("role");  // Fetch role from localStorage
        return {
          url: `/parameters/realtime-values/?site_id=${id}&role=${role}`,
          method: "GET",
          headers: HEADERS,
        };
      },
    }),

    getParameterData: builder.query({
      query: () => ({
        url: "/parameters/realtime-values",
        method: "GET",
        headers: HEADERS,
      }),
    }),
  }),
});


export const {
  useGetSiteSummaryDataQuery,
  useGetCurrentSiteDataQuery,
  useGetVendorCurrentSiteDataQuery,
  useGetCurrentSiteVendorDataQuery,
  useGetCurrentSiteParameterDataQuery,
  useGetParameterDataQuery,
} = siteSummaryApi;

import React from "react";
import { generateReportApi } from "../../Store/Slices/generateReportSlice";
import { ApiProvider } from "@reduxjs/toolkit/query/react";
import AverageGenerateReport from "./AverageGenerateReport";

const AverageReport = () => {

  return (
    <ApiProvider api={generateReportApi}>
      <AverageGenerateReport />
    </ApiProvider>
  );

};


export default AverageReport;






import React, { useState } from "react";
import "./CustomReport.css";

const FilteredReportTable = ({ averageReport }) => {

  // console.log("average row", averageReport)

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 50;

  // Mapping for specific column names
  const columnNameMapping = {
    transaction_at: "Timestamp",
  };

  // Ensure averageReport is not empty or undefined
  if (!Array.isArray(averageReport) || averageReport.length === 0) {
    return (
      <div>
        <p>No data available to display.</p>
      </div>
    );
  }


  // Generate the header names with mapping applied
  const parameterNames = Object.keys(averageReport[0]).map((name) =>
    columnNameMapping[name] || name
  );

  // Helper function to format the timestamp
  const formatTimestamp = (timestamp) => {
    if (timestamp && typeof timestamp === "string" && timestamp.includes("T")) {
      return timestamp.replace("T", " ").replace("Z", ""); // Replace 'T' with a space and remove 'Z'
    }
    return timestamp;
  };

  // Calculate pagination boundaries
  const totalRows = averageReport.length;

  console.log(totalRows);
  
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startRow = (currentPage - 1) * rowsPerPage;
  const endRow = startRow + rowsPerPage;

  const paginatedRows = averageReport.slice(startRow, endRow);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      {/* Display total number of rows */}
      <div className="row-count">
        <p>Total Rows: {totalRows}</p>
      </div>

      <div className="filteredreport__table">
        <div className="filtered-report-table-wrapper">
          <table className="filtered-report-table">
            <thead>
              <tr>
                <th>Sr. No</th>
                {parameterNames.map((parameterName, index) => (
                  <th key={index}>{parameterName}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedRows.map((row, index) => (
                <tr key={index}>
                  <td>{startRow + index + 1}</td>
                  {Object.keys(row).map((key) => (
                    <td key={key}>
                      {key === "transaction_at"
                        ? formatTimestamp(row[key]) // Format timestamp
                        : row[key]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button onClick={goToPreviousPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={goToNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
};

export default FilteredReportTable;


// import React, { useEffect } from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
// import "./CustomReport.css";
// import moment from "moment";

//  const FilteredReportGraph = ({
//   averageReport,
//   startDate,
//   endDate,
//   station,
//   siteName,
//  }) => {
//   const formatTimestamp = (timestamp) => {
//     return moment(timestamp).format("HH:mm:ss");
//   };

//   // Extract parameter names from the first data object
//   const parameterNames = Object?.keys(averageReport[0]).filter(
//     (key) => key !== "timestamp"
//   );

//   const colors = [
//     "#1f77b4", // Dark blue
//     "#ff7f0e", // Dark orange
//     "#2ca02c", // Dark green
//     "#d62728", // Dark red
//     "#9467bd", // Dark purple
//     "#e377c2", // Dark pink
//     "#bcbd22", // Dark yellow-green
//     "#17becf", // Dark cyan
//   ];

//   const handlePrint = () => {
//     const printWindow = window.open(
//       "Test",
//       "Envicrafts Average Report Graph View"
//     );
//     const content = document.querySelector(".printGraph__popup");
//     const styles = `
//       <style>
//         /* Add any custom styles for printing here */
//         @media print {
//           .filteredreport__graphView {
//             display: none;
//           }
//         }
//       </style>
//     `;

//     const html = styles + content.outerHTML;
//     printWindow.document.write(html);
//     printWindow.document.close();

//     // Wait for a small delay before triggering print to ensure content is loaded
//     setTimeout(() => {
//       printWindow.print();
//       printWindow.close();
//     }, 1000);
//   };

//   // Remove the print styles when the component is unmounted
//   useEffect(() => {
//     return () => {
//       const styleTags = document.querySelectorAll("style");
//       styleTags.forEach((tag) => {
//         if (tag.textContent.includes("@media print")) {
//           tag.remove();
//         }
//       });
//     };
//   }, []);

//   return (
//     <div className="filteredreport__graphView">
//       <div className="filteredreport__graphView__header">
//         <h1>Graph View</h1>
//         <button
//           className="filteredreport__graphView__header__button"
//           onClick={handlePrint}
//         >
//           Print Graph
//         </button>
//       </div>
//       <div className="printGraph__popup">
//         <div className="printGraph__popup__header__details__wrapper">
//           <p>
//             Average Report generated for station {station} from {startDate} to{" "}
//             {endDate}
//           </p>
//         </div>
//         <ResponsiveContainer
//           width={"90%"}
//           height={"80%"}
//           className="userdata__graph"
//         >
//           <LineChart width={"100%"} height={"100%"} data={averageReport}>
//             <CartesianGrid strokeDasharray="3 3" />
//             <XAxis dataKey="timestamp" tickFormatter={formatTimestamp} />
//             <YAxis />
//             <Tooltip />
//             <Legend />
//             {parameterNames.map((parameterName, index) => (
//               <Line
//                 key={index}
//                 type="monotone"
//                 dataKey={parameterName}
//                 stroke={colors[index % colors.length]} // Use colors from the array based on index 
//               />
//             ))}           
//           </LineChart>          
//         </ResponsiveContainer>                             
//         <div className="printGraph__popup__footer">      

//           {/* Add your company name or other footer content here */}
//           TechniCrafts Soft Solutions LLP

//         </div>
//       </div>
//     </div>
//   );
// };

// export default FilteredReportGraph;




import React, { useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "./CustomReport.css";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";

const FilteredReportGraph = ({
  averageReport,
  startDate,
  endDate,
  station,
  siteName,
}) => {
  const formatTimestamp = (timestamp) => {
    return moment(timestamp).format("HH:mm:ss");
  };

  // Extract parameter names from the first data object
  const parameterNames = Object?.keys(averageReport[0]).filter(
    (key) => key !== "timestamp"
  );

  const colors = [
    "#1f77b4", // Dark blue
    "#ff7f0e", // Dark orange
    "#2ca02c", // Dark green
    "#d62728", // Dark red
    "#9467bd", // Dark purple
    "#e377c2", // Dark pink
    "#bcbd22", // Dark yellow-green
    "#17becf", // Dark cyan
  ];

  const handleDownloadPDF = () => {
    const doc = new jsPDF();

    // Add title and details
    doc.setFontSize(14);
    doc.text(`Average Report for Station: ${station}`, 10, 10);
    doc.text(`Site Name: ${siteName}`, 10, 20);
    doc.text(`From: ${startDate} To: ${endDate}`, 10, 30);

    // Add chart as an image
    const chartElement = document.querySelector(".userdata__graph");
    if (chartElement) {
      const svg = chartElement.querySelector("svg");
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const DOMURL = window.URL || window.webkitURL || window;

      const img = new Image();
      const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
      const url = DOMURL.createObjectURL(svgBlob);

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        DOMURL.revokeObjectURL(url);

        const imgData = canvas.toDataURL("image/png");
        doc.addImage(imgData, "PNG", 10, 40, 190, 90); // Adjust dimensions as needed

        // Add footer
        doc.setFontSize(12);
        doc.text("TechniCrafts Soft Solutions LLP", 10, 140);

        // Save the PDF
        doc.save("Average_Report.pdf");
      };

      img.src = url;
    }
  };

  return (
    <div className="filteredreport__graphView">
      <div className="filteredreport__graphView__header">
        <h1>Graph View</h1>
        <button
          className="filteredreport__graphView__header__button"
          onClick={handleDownloadPDF}
        >
          Download PDF
        </button>
      </div>
      <div className="printGraph__popup">
        <div className="printGraph__popup__header__details__wrapper">
          <p>
            Average Report generated for station {station} from {startDate} to {endDate}
          </p>
        </div>
        <ResponsiveContainer
          width={"90%"}
          height={"80%"}
          className="userdata__graph"
        >
          <LineChart width={"100%"} height={"100%"} data={averageReport}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="timestamp" tickFormatter={formatTimestamp} />
            <YAxis />
            <Tooltip />
            <Legend />
            {parameterNames.map((parameterName, index) => (
              <Line
                key={index}
                type="monotone"
                dataKey={parameterName}
                stroke={colors[index % colors.length]} // Use colors from the array based on index
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
        <div className="printGraph__popup__footer">
          TechniCrafts Soft Solutions LLP
        </div>
      </div>
    </div>
  );
};

export default FilteredReportGraph;


// import React, { useEffect, useRef } from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
// import moment from "moment";
// import html2canvas from "html2canvas";
// import "./CustomReport.css";
// import jsPDF from "jspdf";
// import "jspdf-autotable";

// const FilteredReportGraph = ({ averageReport, startDate, endDate, station, siteName }) => {
//   const chartRef = useRef();

//   // Replace `transaction_at` with `Timestamp` and update the key in the data
//   const processedReport = averageReport.map((item) => {
//     const updatedItem = { ...item };
//     if (updatedItem.transaction_at) {
//       updatedItem.Timestamp = updatedItem.transaction_at; // Replace 'transaction_at' with 'Timestamp'
//       delete updatedItem.transaction_at; // Remove the old key
//     }
//     return updatedItem;
//   });

//   // Format timestamp
//   const formatTimestamp = (timestamp) => {
//     return moment(timestamp).format("HH:mm:ss");
//   };

//   // Get parameter names excluding the 'Timestamp' field
//   const parameterNames = Object.keys(processedReport[0]).filter(
//     (key) => key !== "Timestamp" // Exclude the 'Timestamp' field
//   );

//   const colors = [
//     "#1f77b4",
//     "#ff7f0e",
//     "#2ca02c",
//     "#d62728",
//     "#9467bd",
//     "#e377c2",
//     "#bcbd22",
//     "#17becf",
//   ];

//   const exportToPdf = () => {
//     // Use html2canvas to capture the chart as an image
//     html2canvas(chartRef.current, { scale: 2 }).then((canvas) => {
//       // Create a new jsPDF instance with landscape orientation
//       const pdf = new jsPDF("l", "mm", "a4"); // 'l' for landscape, 'a4' for paper size
    
//       // Add the header text to the PDF
//       const headerText = `Average Report generated for station ${station} from ${startDate} to ${endDate}`;
//       pdf.setFontSize(12);
//       pdf.text(headerText, 50, 10); // Add the header text at position (x, y)
    
//       // Calculate the dimensions of the chart image
//       const imgData = canvas.toDataURL("image/png");
    
//       // A4 dimensions in mm for landscape: 297mm width x 210mm height
//       const pageWidth = 250; // A4 landscape page width
//       const pageHeight = 190; // A4 landscape page height
    
//       // Get the chart's width and height in pixels
//       const chartWidth = canvas.width;
//       const chartHeight = canvas.height;
  
//       // Calculate the scaling factor to fit the image within the PDF page width
//       const scaleFactor = pageWidth / chartWidth;
  
//       // Calculate the image width and height to fit it on the PDF page
//       let imgWidth = chartWidth * scaleFactor; // Image width based on the scaling factor
//       let imgHeight = chartHeight * scaleFactor; // Image height based on the scaling factor
    
//       // If the image height exceeds the PDF page height, scale it down further
//       if (imgHeight > pageHeight) {
//         const scaleFactorHeight = pageHeight / imgHeight;
//         const scaleFactorWidth = pageWidth / imgWidth;
//         const finalScaleFactor = Math.min(scaleFactorHeight, scaleFactorWidth);
  
//         // Apply the final scale factor
//         imgWidth = imgWidth * finalScaleFactor;
//         imgHeight = imgHeight * finalScaleFactor;
//       }
    
//       // Add the image to the PDF (x, y, width, height)
//       pdf.addImage(imgData, "PNG", 10, 20, imgWidth, imgHeight); // Adjust y-position to leave space for the header
    

//       const footerText = "TechniCrafts Soft Solutions LLP";
//       const footerY = pageHeight - 10; // Positioning footer 10mm from the bottom of the page
//       pdf.setFontSize(10);
//       pdf.text(footerText, (pageWidth - pdf.getTextWidth(footerText)) / 2, footerY);
      
//       // Save the PDF with the filename
//       pdf.save("report_graph.pdf");
//     });
//   };
  
  
  
  
//   useEffect(() => {
//     return () => {
//       const styleTags = document.querySelectorAll("style");
//       styleTags.forEach((tag) => {
//         if (tag.textContent.includes("@media print")) {
//           tag.remove();
//         }
//       });
//     };
//   }, []);

//   return (
//     <div className="filteredreport__graphView">
//       <div className="filteredreport__graphView__header">
//         <h1>Graph View</h1>
//         <button
//           className="filteredreport__graphView__header__button"
//           onClick={exportToPdf}
//         >
//           Download PDF
//         </button>
//       </div>
//       <div className="printGraph__popup">
//         <div className="printGraph__popup__header__details__wrapper">
//           <p>
//             Average Report generated for station {station} from {startDate} to{" "}
//             {endDate}
//           </p>
//         </div>
//         <ResponsiveContainer width="600" height={500}>
//   <div ref={chartRef}>
//     <LineChart width={800} height={400} data={processedReport}>
//       <CartesianGrid strokeDasharray="3 3" />
//       <XAxis dataKey="Timestamp" tickFormatter={formatTimestamp} />
//       <YAxis />
//       <Tooltip />
//       <Legend />
//       {parameterNames.map((parameterName, index) => (
//         <Line
//           key={index}
//           type="monotone"
//           dataKey={parameterName}
//           stroke={colors[index % colors.length]}
//         />
//       ))}
//     </LineChart>
//   </div>
// </ResponsiveContainer>

//         <div className="printGraph__popup__footer">
//           TechniCrafts Soft Solutions LLP
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FilteredReportGraph;

// src/Store/Slices/cpcbReportSlice.js

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const cpcbReportApi = createApi({
  reducerPath: 'cpcbReportApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'http://192.168.1.107:6060/sites/' }),
  endpoints: (builder) => ({
    fetchOfflineReport: builder.query({
      query: () => 'OfflineReport/',
    }),
    fetchSmsReport: builder.query({
      query: () => 'CpcbReport/',
    }),
    fetchNoSmsReport: builder.query({
      query: () => 'NoSmsReport/',
    }),
    downloadReport: builder.mutation({
      query: (reportType) => ({
        url: `${reportType}/download`,
        method: 'GET',
        responseType: 'blob', // assuming you're downloading a file
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useFetchOfflineReportQuery,
  useFetchSmsReportQuery,
  useFetchNoSmsReportQuery,
  useDownloadReportMutation,
} = cpcbReportApi;

export default cpcbReportApi;


import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, HEADERS } from "../../api";

export const calibrationApi = createApi({
  reducerPath: "calibrationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: (builder) => ({
    // GET calibration data
    getCalibrationData: builder.query({
      query: () => ({
        url: "calibration/get-calibration",
        method: "GET",
        headers: HEADERS,
      }),
    }),
    // POST calibration data
    createCalibrationData: builder.mutation({
      query: (postData) => ({
        url: "calibration/create-calibration",
        method: "POST",
        headers: HEADERS,
        body: postData,
      }),
    }),
  }),
});

export const { useGetCalibrationDataQuery, useCreateCalibrationDataMutation } = calibrationApi;

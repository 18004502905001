import { useState,useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { TextField } from "@mui/material";
import Loader from "../../global/Loader/Loader";
import Error from "../../global/Error/Error";
import { useGetVendorCurrentSiteDataQuery } from "../../Store/Slices/siteSummarySlice";
import SiteStatusData from "./SiteStatusData";

export default function SimpleTable() {
  const [isOpen, setIsOpen] = useState(false);
  const [siteId, setSiteId] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const today = new Date();

  const res = useGetVendorCurrentSiteDataQuery();
  console.log("vendor sites",res);

  const handlePopup = (VendorName) => {
    localStorage.setItem("viewedSiteId", VendorName);
    setIsOpen(true);
    setSiteId(VendorName);
  };

  // useEffect(() => {
  //   const viewedSiteId = localStorage.getItem("viewedSiteId");
  //   console.log("Viewed Site ID:", viewedSiteId);

  //   if (viewedSiteId) {
  //     setIsOpen(true);
  //     setSiteId(viewedSiteId);

  //   }
  // }, []);

  console.log(siteId);
  if (res.isLoading) return <Loader />;
  if (res.isError) return <Error />;

  const columns = [
    { field: "site_id", headerName: "ID", width: 70,
      valueGetter: (params) => res.data.indexOf(params.row) + 1
     },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <button
          className="sitestatus__table__btn"
          onClick={() => handlePopup(params.row.site_id)}
        >
          View Details
        </button>
      ),
    },
    { field: "name", headerName: "Name", width: 250,
      renderCell: (params) => {
        const expiryDate = new Date(params.row.stations[0]?.expiry_date || today);
        const isExpired = expiryDate < today;
        return (
          <span className={isExpired ? "expired-name" : ""}>
            {params.value}
          </span>
        );
      },
     },
    { field: "label", headerName: "Label", width: 150 },
    { field: "category", headerName: "Category", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <span className={`status-cell ${params.value.toLowerCase()}`}>
          {params.value}
        </span>
      ),
    },
    { field: "authority", headerName: "Authority", width: 150 },
    { field: "state", headerName: "State", width: 150 },
    { field: "city", headerName: "City", width: 150 },
    { field: "today_exceedance", headerName: "Today Exceedance",width: 200,},
    { field: "address", headerName: "Address", width: 200 },
    { field: "latitude", headerName: "Latitude", width: 150 },
    { field: "longitude", headerName: "Longitude", width: 150 },
    {
      field: "stations",
      headerName: "Stations",
      width: 250,
      valueGetter: (params) =>
        params.row.stations
          ? params.row.stations.map((s) => s.station).join(",")
          : "",
    },
    {
      field: "parameters",
      headerName: "Parameters",
      width: 450,
      valueGetter: (params) =>
        params.row.stations.map((value) =>
          value.parameters.map((item) => item)
        ),
    },
  ];
 
  // Function to handle search query change
  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter rows based on searchQuery
  const filteredRows = res.data.filter((row) =>
    row.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  console.log("filtered", filteredRows)


  return (
    <div className="sitestatus">

      <div className="sitestatus__header">
        
      <h1 style={{ display: "flex", alignItems: "center", gap: "20px" }}>
  Vendor Site Status
  <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
    <span className="status-indicator">
      <span className="status-circle inactive-site"></span> Inactive
    </span>
    <span className="status-indicator">
      <span className="status-circle expired-site"></span> Expired
    </span>
  </div>
     </h1>
        <TextField
          label="Search for Site Name"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchQueryChange}
          className="sitestatus__header__searchbar"
        />
      </div>

      <div
        style={{ height: "100vh", width: "100%" }}
        className="sitestatus__tablewrapper"
      >

        {/* Use DataGrid with filteredRows */}
        <DataGrid
          rows={filteredRows}
          getRowId={(row) => row.site_id}
          sx={{
            ".siteStatus__datatable__header": {
              backgroundColor: "#23395d",
              color: "#fff",
            },
          }}
          columns={columns}
          pageSize={100}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </div>

      {isOpen && <SiteStatusData siteId={siteId} isOpen={isOpen}  setIsOpen={setIsOpen} />}
    </div>

  );
}


import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tabs from "@mui/material/Tabs";
import "./SiteStatus.css";
import { TbBuildingFactory } from "react-icons/tb";
import { MdOutlineWaterDrop } from "react-icons/md";
import { CiTempHigh } from "react-icons/ci";
import Loader from "../../global/Loader/Loader";
import Error from "../../global/Error/Error";

export default function SiteLiveReadings({ res }) {
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    if (res.data) {
      const effluent = res.data.filter((item) => item.monitoring_type === "effluent");
      const emission = res.data.filter((item) => item.monitoring_type === "emission");
      const ambient = res.data.filter((item)  => item.monitoring_type === "ambient");

      if (effluent.length > 0) {
        setValue("1");
      } else if (emission.length > 0) {
        setValue("2");
      } else if (ambient.length > 0) {
        setValue("3");
      }
    }
  }, [res.data]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (res.isLoading) return <Loader />;
  if (res.isError) return <Error />;

  const effluent = res?.data.filter((item) => item.monitoring_type === "effluent");
  const emission = res?.data.filter((item) => item.monitoring_type === "emission");
  const ambient = res?.data.filter((item)  => item.monitoring_type === "ambient");

  return (
    <Box sx={{ width: "100%", display: "flex", typography: "body1" }}>
      <Box sx={{ width: "10%" }}>
        <TabContext value={value}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            orientation="vertical"
            aria-label="lab API tabs example"
          >
            <Tab label="Effluent" value="1" />
            <Tab label="Emission" value="2" />
            <Tab label="Ambient" value="3" />
          </Tabs>
        </TabContext>

      </Box>
      <Box sx={{ width: "100%", backgroundColor: "#1414141c" }}>
        <TabContext value={value}>
          <TabPanel value="1">
            <div className="realtimereport__livereading">
              {effluent?.length > 0 ? (
                effluent.map((item) => {
                  const isFlowParameter = ["flow", "flow rate"].includes(item.parameter.toLowerCase());
                  return(
                  <div className="realtimereport__livereading__card" key={item.id}>
                    <MdOutlineWaterDrop className="realtimereport__livereading__card__icon" />
                    <div className="realtimereport__livereading__card__content">
                      <p>
                        <span
                         style={{
                          color: isFlowParameter
                            ? "green"
                            : item.last_value < item.normal_min || item.last_value > item.normal_max
                            ? "red"
                            : "green",
                          fontSize: "2.2rem",
                          fontWeight: "500",
                        }}>
                          {item.last_value} {item.unit}
                        </span>
                      </p>

                      <p className="realtimereport__livereading__card__content__parameterName">
                        {item.parameter}
                      </p>
                      <p>{item.station}</p>

                      <p>
                        Limit : {item.normal_min} - {item.normal_max} {item.unit}
                      </p>
                      <p>Range : {item.today_min} - {item.today_max}</p>

                      <p> Last Sync Date : {item?.last_sync?.slice(0, 10)}</p>
                      <p> Last Sync Time : {item?.last_sync?.slice(11, 16)}</p>
                    </div>
                  </div>
                  )
                  })
              ) : (
                <div>No Data Available</div>
              )}
            </div>
          </TabPanel>
          <TabPanel value="2">
            <div className="realtimereport__livereading">
              {emission?.length > 0 ? (
                emission.map((item) => (
                
                  <div className="realtimereport__livereading__card" key={item.id}>
                    <TbBuildingFactory className="realtimereport__livereading__card__icon" />
                    <div className="realtimereport__livereading__card__content">
                      <p>
                        <span
                        style={{
                          color:
                          item.last_value < item.normal_min || item.last_value > item.normal_max
                          ? "red"
                          : "green",
                             fontSize: "2.2rem",
                               fontWeight: "500",
                                 }}
                        >
                          {item.last_value} {item.unit}
                        </span>
                      </p>
                      <p className="realtimereport__livereading__card__content__parameterName">
                        {item.parameter}
                      </p>
                      <p>{item.station}</p>

                      <p>
                        Limit : {item.normal_min} - {item.normal_max} {item.unit}
                      </p>
                      <p>
                        Range : {item.today_min} - {item.today_max}
                      </p>
                      <p> Last Sync Date : {item?.last_sync?.slice(0, 10)}</p>
                      <p> Last Sync Time : {item?.last_sync?.slice(11, 16)}</p>
                    </div>
                  </div>
                ))
              ) : (
                <div>No Data Available</div>
              )}
            </div>
          </TabPanel>
          <TabPanel value="3">
            <div className="realtimereport__livereading">
              {ambient?.length > 0 ? (
                ambient.map((item) => (
   
                  <div className="realtimereport__livereading__card" key={item.id}>
                    <CiTempHigh className="realtimereport__livereading__card__icon" />
                    <div className="realtimereport__livereading__card__content">
                      <p>{item.parameter}</p>
                      <p>{item.station}</p>
                      <p>
                        <span
                         style={{
                          color:
                          item.last_value < item.normal_min || item.last_value > item.normal_max
                          ? "red"
                          : "green",
                             fontSize: "2.2rem",
                               fontWeight: "500",
                                 }}
                        >
                          {item.last_value} {item.unit}
                        </span>
                      </p>
                      <p>
                        Limit : {item.normal_min} - {item.normal_max} {item.unit}
                      </p>
                      <p>
                        Range : {item.today_min} - {item.today_max}
                      </p>
                      <p> Last Sync Date : {item?.last_sync?.slice(0, 10)}</p>
                      <p> Last Sync Time : {item?.last_sync?.slice(11, 19)}</p>
                    </div>
                  </div>
                ))
              ) : (
                <div>No Data Available</div>
              )}
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}

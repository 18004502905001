// import React, { useEffect, useState } from 'react';
// import "./UserSubscription.css";
// import { CgDanger } from 'react-icons/cg';

// const SubscriptionPopup = ({ daysLeft, show, onClose }) => {
//   if (!show) return null;

//   return (
//     <div className="popup">
//       <div className="popup-message">
//         <p>Subscription will end in {daysLeft === 1 ? '1 day' : `${daysLeft} days`}  <CgDanger className="subscription-icon" />.</p>
//         <button onClick={onClose} className="subscription-ok-button">OK</button>
//       </div>
//     </div>
//   );
// };

// const UserSubscription = () => {
//   const [showPopup, setShowPopup] = useState(false);
//   const [daysLeft, setDaysLeft] = useState(0);

//   useEffect(() => {
//     checkSubscriptionExpiry();
//   }, []);

//   const checkSubscriptionExpiry = async () => {
//     const siteId = localStorage.getItem('siteId');
//     const apiKey = process.env.REACT_APP_API_KEY;

//     try {
//       const response = await fetch(`http://envicrafts.com:8080/sites/?site_id=${siteId}`, {
//         headers: {
//           Authorization: `Bearer ${apiKey}`,
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         const expiryDate = new Date(data[0].stations[0].expiry_date);
//         const today = new Date();
//         const daysUntilExpiry = Math.ceil((expiryDate - today) / (1000 * 60 * 60 * 24));

//         if (daysUntilExpiry <= 15 && daysUntilExpiry >= 0) {
//           setShowPopup(true);
//           setDaysLeft(daysUntilExpiry);

//           if (daysUntilExpiry === 0) {
//             logoutUser();
//           }

//         }
//       } else {
//         console.error('Failed to fetch subscription data:', response.status);
//       }
//     } catch (error) {
//       console.error('Error fetching subscription data:', error);
//     }
//   };

//   const logoutUser = () => {
//     // Perform logout action here, such as clearing local storage, redirecting to logout page, etc.
//     localStorage.removeItem('siteId'); // Assuming siteId is used for authentication
//     // Redirect user to logout page or perform other logout actions
//   };

//   const handleClosePopup = () => {
//     setShowPopup(false);
//   };

//   return (
//     <div>
//       <SubscriptionPopup daysLeft={daysLeft} show={showPopup} onClose={handleClosePopup} />
//     </div>
//   );
// };

// export default UserSubscription;





// import React, { useEffect, useState } from 'react';     

// import "./UserSubscription.css";
// import { CgDanger } from 'react-icons/cg';     
// import { useNavigate } from 'react-router-dom';    
// import LogoutPopup from '../../global/LogOutPopup/LogoutPopup';

// const SubscriptionPopup = ({ daysLeft, show, onClose }) => {
//   if (!show) return null;

//   return (
//     <div className="popup"> 
//       <div className="popup-message"> 
//         <p>Subscription will end in {daysLeft === 1 ? '1 day' : `${daysLeft} days`}  <CgDanger className="subscription-icon" />.</p>
//         <button onClick={onClose} className="subscription-ok-button">OK</button>
//       </div>
//     </div>
//   );
// };


// const UserSubscription = () => {
//   const [showPopup, setShowPopup] = useState(false);
//   const [daysLeft, setDaysLeft] = useState(0);
//   const navigate = useNavigate();

//   useEffect(() => {
//     checkSubscriptionExpiry();
//   }, []);

//   useEffect(() => {
//     const timer = setInterval(() => {
//       checkSubscriptionExpiry();
//     }, 86400000); // Check every 24 hours

//     return () => clearInterval(timer); // Clear the interval when component unmounts
//   }, []);

//     const checkSubscriptionExpiry = async () => {
//     const siteId = localStorage.getItem('siteId');
//     const apiKey = process.env.REACT_APP_API_KEY;

//     try {
//       const response = await fetch(`http://envicrafts.com:8080/sites/?site_id=${siteId}`, {
//         headers: {
//           Authorization: `Bearer ${apiKey}`,
//         },
//       });
  
//       if (response.ok) {
//         const data = await response.json();
//         const expiryDate = new Date(data[0].stations[0].expiry_date);
//         const today = new Date();
//         const daysUntilExpiry = Math.ceil((expiryDate - today) / (1000 * 60 * 60 * 24));
  
//         if (daysUntilExpiry <= 0) {
//           logoutUser();
//         } else if (daysUntilExpiry <= 15) {
//           setShowPopup(true);
//           setDaysLeft(daysUntilExpiry);
//         } else {
//           setShowPopup(false); // Do not show popup if more than 15 days left
//           setDaysLeft(daysUntilExpiry);
//         }
//       } else {
//         console.error('Failed to fetch subscription data:', response.status);
//       }
//     } catch (error) {
//       console.error('Error fetching subscription data:', error);
//     }
//   };


//   const handleClosePopup = () => {
//     setShowPopup(false);
//   };

//   const logoutUser = () => {
//     localStorage.removeItem("username");
//     localStorage.removeItem("userpassword");
//     localStorage.removeItem("role");
//     localStorage.removeItem("siteId");
//     localStorage.setItem("login", "false");
//     navigate("/"); // Redirect to home page after logout
//   };

//   return (
//     <div>
//       <SubscriptionPopup daysLeft={daysLeft} show={showPopup} onClose={handleClosePopup} />
//       {daysLeft === 0 && <LogoutPopup />}
//     </div>
//   );
// };

// export default UserSubscription;


import React, { useEffect, useState } from 'react';     
import "./UserSubscription.css";
import { useNavigate } from 'react-router-dom';    
import LogoutPopup from '../../global/LogOutPopup/LogoutPopup';

const SubscriptionPopup = ({ stationsAboutToExpire, stationsExpired, show, onClose }) => {
  if (!show) return null;

  let message = '';

  if (stationsExpired.length > 0) {
    message += ` ${stationsExpired.join(', ')}  have expired. `;
  }
  if (stationsAboutToExpire.length > 0) {
    if (message.length > 0) message += 'And ';
    message += ` ${stationsAboutToExpire.map(station => 
      `${station.name} (${station.daysLeft} days left to expire )`).join(', ')}. Please renew soon.`;
  }

  return (
    <div className="popup"> 
      <div className="popup-message"> 
        <p>{message}</p>
        <button onClick={onClose} className="subscription-ok-button">OK</button>
      </div>
    </div>
  );
};

const UserSubscription = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [stationsExpired, setStationsExpired] = useState([]);
  const [stationsAboutToExpire, setStationsAboutToExpire] = useState([]);
  const [totalStations, setTotalStations] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    checkSubscriptionExpiry();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      checkSubscriptionExpiry();
    }, 86400000); // Check every 24 hours

    return () => clearInterval(timer);
  }, []);

  const checkSubscriptionExpiry = async () => {
    const siteId = localStorage.getItem('siteId');
    const apiKey = process.env.REACT_APP_API_KEY;

    try {
      const response = await fetch(`http://envicrafts.com:8080/sites/?site_id=${siteId}`, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const today = new Date();
        let expiredStations = [];
        let expiringSoonStations = [];
        
        // Get the number of stations
        const stations = data[0].stations;
        setTotalStations(stations.length);

        stations.forEach(station => {
          const expiryDate = new Date(station.expiry_date);
          const daysUntilExpiry = Math.ceil((expiryDate - today) / (1000 * 60 * 60 * 24));

          if (daysUntilExpiry <= 0) {
            expiredStations.push(station.station);
          } else if (daysUntilExpiry <= 30) {
            expiringSoonStations.push({ name: station.station, daysLeft: daysUntilExpiry });
          }
        });

        if (expiredStations.length > 0 || expiringSoonStations.length > 0) {
          setStationsExpired(expiredStations);
          setStationsAboutToExpire(expiringSoonStations);
          setShowPopup(true);
        } else {
          setShowPopup(false);
        }
      } else {
        console.error('Failed to fetch subscription data:', response.status);
      }
    } catch (error) {
      console.error('Error fetching subscription data:', error);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div>
      <SubscriptionPopup 
        stationsAboutToExpire={stationsAboutToExpire} 
        stationsExpired={stationsExpired} 
        show={showPopup} 
        onClose={handleClosePopup} 
      />
      {stationsExpired.length > 0 && totalStations === 1 && (
        <LogoutPopup message="Your session has ended due to expired subscriptions." />
      )}
    </div>
  );
};

export default UserSubscription;

import "./Login.css";
import { useState, useEffect } from "react";
import backGroundImg from "./Images/BackgroundImg.jpg";
import Img from "./Images/Technicrafts.png";
import logo from "./Images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import AfterLoginLoader from "./AfterLoginLoader";
import { RxEyeOpen, RxEyeClosed } from "react-icons/rx";
import { BASE_LOGIN_URL } from "../api";
import { setSession } from "../global/utils/sessionUtils";

const Login = () => {
  const [step, setStep] = useState("adminuser");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [registeredEmail, setRegisteredEmail] = useState(""); 

  const navigate = useNavigate();

  const OTP_VERIFY_URL = "http://envicrafts.com:8080/users/verify-otp/"; // For OTP verification

  useEffect(() => {
    if (localStorage.getItem("login")) {
      const role = localStorage.getItem("role");
      if (role === "Admin") {
        navigate("/admin");
      } else if (role === "Operator") {
        navigate("/operator");
      }
      else if (role === "Vendor") {
        navigate("/vendor");
      }
      else if (role === "CPCBAdmin") {
        setStep("cpcbadmin"); // Move to OTP verification for CPCBAdmin
      }
    }
  }, [navigate]);

  const loginData = {
    username: username,
    password: password,
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + process.env.REACT_APP_API_KEY,
  };

  async function handleSubmit(e) {

    e.preventDefault();

    try {
      setLoading(true);
      const response = await axios.post(BASE_LOGIN_URL, loginData, { headers });
      setUserData(response.data);
      console.log(response);

      if (response.data?.data) {
        if (response.data.data.role === "Admin") {
          localStorage.setItem("login", true);
          localStorage.setItem("username", username);
          localStorage.setItem("userpassword", password);
          localStorage.setItem("role", "admin");
          setTimeout(() => {
            setLoading(false);
            navigate("/admin");
          }, 7000);
        }

        else if (response.data.data.role === "Vendor") {
          localStorage.setItem("login", true);
          localStorage.setItem("username", username);
          localStorage.setItem("userpassword", password);
          localStorage.setItem("role", "vendor");
          const VendorName = String(response.data.data.vendor);
          console.log(VendorName)
          localStorage.setItem("VendorName", VendorName);
          setTimeout(() => {
            setLoading(false);
            navigate("/vendor");
          }, 7000);
        } 

        else if (response.data.data.role === "CPCBAdmin") {
          localStorage.setItem("username", username);
          localStorage.setItem("userpassword", password);
          localStorage.setItem("role", "cpcb");
          setLoading(false);
          setStep("cpcbadmin"); 
       
          
          
  const emailMessage = response.data.data.message; // Extract email message
  setRegisteredEmail(emailMessage);  

          setUserData({
            ...userData,
            // message: `${registeredEmail}.`,
          });
          setTimeout(() => {
            setUserData((prevData) => ({
              ...prevData,
              message: "", 
            }));
          }, 6000);
        }

        else if (response.data.data.role === "Operator") {
          localStorage.setItem("login", "true");
          localStorage.setItem("username", username);
          localStorage.setItem("userpassword", password);
          localStorage.setItem("role", "operator");
          const siteId = Number(response.data.data.site_id);
          localStorage.setItem("siteId", siteId);
          // Log the localStorage values to confirm they're set correctly
          console.log("login status:", localStorage.getItem("login"));
          console.log("role:", localStorage.getItem("role"));
          console.log("siteId:", localStorage.getItem("siteId"));
          setTimeout(() => {
            setLoading(false);
            navigate("/operator");
          }, 7000);
        }
        

        else {
          setLoading(false);
          console.log("Unknown role:", response.data.data.role);
        }

        setSession();
      //  navigate("/dashboard");
      } else {
        setLoading(false);
        console.log("Unexpected response:", response.data);
      }

    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }


  async function handleOtpSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const otpData = { username, otp };
      const response = await axios.post(OTP_VERIFY_URL, otpData, { headers });

      if (response.status === 200) {
        localStorage.setItem("login", "true");
        localStorage.setItem("role", "cpcb");
        navigate("/cpcb"); // Navigate to admin dashboard
      } else {
        setError("Invalid OTP");
      }
    } catch (error) {
      setError("Failed to verify OTP");
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="login"> 
      <div className="login__wrapper"> 
        <div className="login__container"> 
          <div className="login__container__wrapper"> 
            <span>Welcome to EnviCrafts</span> 
            <h1> 
              Log into your Account
            </h1>

            {/* Render Username & Password Form or OTP Form based on step */}
            {step === "adminuser" && (
              <form className="login__form" onSubmit={handleSubmit}>
                {userData?.message && (
                  <p className="login__form__message">{userData?.message}</p>
                )}
                <input
                  type="text"
                  placeholder="Username"
                  required
                  value={username}
                  className={userData?.message ? "incorrect" : null}
                  onChange={(e) => setUsername(e.target.value)}
                />

                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className={userData?.message ? "incorrect" : null}
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{ position: "relative" }}
                />

                {showPassword ? (
                  <RxEyeClosed
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                    className="showpass"
                  />
                ) : (
                  <RxEyeOpen
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                    className="showpass"
                  />
                )}

                <button type="submit" className="loginBtn">
                  {loading ? "Loading" : "Submit"}
                </button>
              </form>
            )}

            {/* OTP Verification Form for CPCBAdmin */}
            {step === "cpcbadmin" && (
              <form className="login__form" onSubmit={handleOtpSubmit}>
                 {registeredEmail && (
             <p style={{ color: "green" }}>{registeredEmail} </p>
             )}


                <div>
                  <input 
                    type="text" 
                    placeholder="Enter OTP"
                    value={otp} 
                    onChange={(e) => setOtp(e.target.value)} 
                    className={userData?.message ? "incorrect" : null}
                    required 
                  />
                </div>
                {error && <div style={{ color: 'red' }}>{error}</div>}
                <button type="submit" disabled={loading} className="loginBtn">
                  {loading ? 'Verifying OTP...' : 'Submit OTP'}
                </button>
              </form>
            )}

            <Link to="/forgotpassword" className="f_pass__link">
              Forgot Password
            </Link>
          </div>

          <div className="login__container__footer">
            <a href="http://technicrafts.in/">@Technicrafts 2023</a>
            <span>v2.O</span>
          </div>
        </div>

        <div className="login__rightside__container">
          <img
            src={backGroundImg}
            alt="background"
            className="login__rightside__container__bgImg"
          />

          <div className="login__logo">
            <img src={logo} alt="logo" className="login__logo__img1" />
            <img src={Img} alt="Technicrafts" className="login__logo__img2" />
          </div>
        </div>
      </div>

      {loading && userData?.status === "success" && (
        <AfterLoginLoader userData={userData} />
      )}
    </div>
  );
};

export default Login;






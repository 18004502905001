import React from "react";
import { useState, useEffect } from "react";
import { MenuButton, MenuItem, MenuList, Menu, Button } from "@chakra-ui/react";
import { ChevronDownIcon, BellIcon } from "@chakra-ui/icons";
import "./Topbar.css";
import { Link } from "react-router-dom";
import Logo from "../../Images/logo.png";
import { FaRegUserCircle } from "react-icons/fa";
import { BiMenu } from "react-icons/bi";
import {
  MdExitToApp,
  MdKeyboardArrowRight,
  MdFullscreen,
  MdFullscreenExit,
} from "react-icons/md";
import { BsArrowsFullscreen, BsFullscreenExit } from "react-icons/bs";
import NotificationPopup from "../NotificationPopup/NotificationPopup";
import { AnimatePresence } from "framer-motion";
import { RxDotFilled } from "react-icons/rx";
import ResponsiveTopbar from "../ResponseTopbar/ResponsiveTopbar";
import { RiUserLine } from "react-icons/ri";
import LogoutPopup from "../LogOutPopup/LogoutPopup";

const Topbar = ({ userType, totalLength,totalNotifications }) => {
  const [notfication, setNotifications] = useState(true); // Notifications Alert
  const [handlePopUp, setHandlePopUp] = useState(false); //
  const [notificationPopup, setNotificationPopup] = useState(true); //  Notification Popup
  const [handleResponsiveMenuToggle, setHandleResponsiveMenuToggle] = useState(false); // Responsive Menu
  const [isFullscreen, setIsFullscreen] = useState(false); // Fullscreen Toggle

  // Notification Popup on Navbar
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setNotificationPopup(false);
    }, 5000); // 5 seconds
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  // Handling Fullscreen Toggle
  const handleToggleFullscreen = () => {
    if (!isFullscreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    setIsFullscreen(!isFullscreen);
  };

  // Admin Navbar
  if (userType === "admin") {
    return (
      <>
        <div className="topbar">
        <Link to={"/admin"}>
          <div className="topbar__logo">
            <img src={Logo} alt="" />
          </div>
          </Link> 
          <div className="topbar__menu">

            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="topbar__menuBtn"
              >
                Dashboard 
              </MenuButton>
              <MenuList className="topbar__menulist">
                <MenuItem className="topbar__menulist__menuItem">
                  <Link to={"/admin"} className="topbar__menuLink">
                    Overall Summary 
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                    </Link>
                </MenuItem>
                <MenuItem className="topbar__menulist__menuItem">
                  <Link to={"/admin/sitesummary"} className="topbar__menuLink">
                    Site Summary 
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="topbar__menuBtn"
              >
                Live Status 
              </MenuButton>
              <MenuList className="topbar__menulist">
                <MenuItem className="topbar__menulist__menuItem">
                  <Link to={"/admin/sitestatus"} className="topbar__menuLink">
                    Site Status 
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                <MenuItem className="topbar__menulist__menuItem">
                  <Link to={"/admin/mapview"} className="topbar__menuLink">
                    Map View 
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>      
                <MenuItem className="topbar__menulist__menuItem">
                  <Link className="topbar__menuLink" to={"/admin/camerastream"}>
                    Camera Stream 
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>

              </MenuList>
            </Menu>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="topbar__menuBtn"
              >
                Reports 
              </MenuButton>
              <MenuList className="topbar__menulist">

              <MenuItem className="topbar__menulist__menuItem">
                  <Link to={"/admin/averagereport"} className="topbar__menuLink">
                    Average Report  
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>

                <MenuItem className="topbar__menulist__menuItem">
                  <Link to={"/admin/customreport"} className="topbar__menuLink">
                    Custom Report  
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>

                <MenuItem className="topbar__menulist__menuItem">
                  <Link 
                  // to={"/admin/Totalizerreport"} 
                  className="topbar__menuLink wip">
                    Totalizer Report  
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>

                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                  // to={"/admin/ExceedanceReport"}
                    className="topbar__menuLink wip"
                  >
                    Exceedance Report  
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                <MenuItem className="topbar__menulist__menuItem">
                  <Link className="topbar__menuLink wip">
                    Scheduled Report{" "} 
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                <MenuItem className="topbar__menulist__menuItem">
                  <Link className="topbar__menuLink wip">
                    Station Count Report
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                <MenuItem className="topbar__menulist__menuItem">
                  <Link className="topbar__menuLink wip">
                    Site Status Report
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                <MenuItem className="topbar__menulist__menuItem">
                  <Link className="topbar__menuLink wip">
                    Data Avaibility
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                <MenuItem className="topbar__menulist__menuItem">
                  <Link className="topbar__menuLink wip">
                    Percentile Report
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="topbar__menuBtn"
              >
                Stations
              </MenuButton>
              <MenuList className="topbar__menulist">
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    to={"/admin/stationstatus"}
                    className="topbar__menuLink"
                  >
                    Station Status{" "}
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    to="/admin/parametersummary"
                    className="topbar__menuLink"
                  >
                    Parameters{" "}
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="topbar__menuBtn"
              >
                Analytics
              </MenuButton>
              <MenuList className="topbar__menulist">
                <MenuItem className="topbar__menulist__menuItem">
                  <Link className="topbar__menuLink wip">
                    Multi Axis Report
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                <MenuItem className="topbar__menulist__menuItem">
                  <Link className="topbar__menuLink wip">
                    Matrix Report
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>

            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="topbar__menuBtn"
              >
                   Calibration
              </MenuButton>
              <MenuList className="topbar__menulist">
                <MenuItem className="topbar__menulist__menuItem">
                  <Link 
                  // to="/admin/calibration"
                    className="topbar__menuLink wip">
                     Calibration
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                <MenuItem className="topbar__menulist__menuItem">
                  <Link 
                  // to="/admin/remotecalibration"
                   className="topbar__menuLink wip">
                    Calibration Logs
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
           
              </MenuList>
            </Menu>

            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="topbar__menuBtn"
              >
                WorkFlow
              </MenuButton>
              <MenuList className="topbar__menulist">
                <MenuItem className="topbar__menulist__menuItem">
                  <Link to={"/admin/workflowCompleted"} className="topbar__menuLink">
                    WorkFlow Data
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                    </Link>
                </MenuItem>
                {/* <MenuItem  className="topbar__menulist__menuItem">
                  <Link to={"/admin/workflow"} className="topbar__menuLink">
                    WorkFlow Completed
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                    </Link>
                </MenuItem> */}  
               </MenuList>
            </Menu>

            <Menu>
              <Link
                to={"/admin/cpcbreports"}
                className="topbar__menuBtn">      
                CPCB Reports
              </Link>
            </Menu>
          </div>

          <div className="topbar__myaccount">

            <Menu>        
              <MenuButton as={Button} className="topbar__menuBtn">
                <RiUserLine className="topbar__myaccount__icon" />
              </MenuButton>
            <MenuList className="topbar__menulist">
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    to={"/admin/myaccount"}
                    className="topbar__menuLink"
                  >
                    My Account{" "}
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    onClick={handleToggleFullscreen}
                    className="topbar__menuLink"
                  >
                    Full Screen Mode
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    className="topbar__menuLink"
                    onClick={() => setHandlePopUp(true)}
                  >
                    Log Out
                  <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        </div>

        {handlePopUp && <LogoutPopup setHandlePopUp={setHandlePopUp} />}

        <div className="hamburger__menu">
          {handleResponsiveMenuToggle ? (
            <MdExitToApp
              onClick={() => setHandleResponsiveMenuToggle(false)}
              className="responsiveMenu__toggle__icon"
            />
          ) : (
            <BiMenu
              onClick={() => {
                setHandleResponsiveMenuToggle(true);
              }}
              className="responsiveMenu__toggle__icon"
            />
          )}
        </div>
        <AnimatePresence>
          {handleResponsiveMenuToggle && (
            <ResponsiveTopbar
              setHandleResponsiveMenuToggle={setHandleResponsiveMenuToggle}
              userType={userType}
            />
          )}
        </AnimatePresence>
      </>
    );
  }


 else if (userType === "vendor") {
    return (
      <>
        <div className="topbar">
        <Link to={"/vendor"}>
          <div className="topbar__logo">
            <img src={Logo} alt="" />
          </div>
        </Link>  

          <div className="topbar__menu">
            <Menu className="topbar__menuBtn">
              <Link
                to="/vendor"
                style={{
                  textDecoration: "none",
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  marginRight: "2rem",
                  color: "#fff",
                }}
              >
                Dashboard
              </Link>
            </Menu>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="topbar__menuBtn"
              >
                Live Status
              </MenuButton>
              <MenuList className="topbar__menulist">
                <MenuItem className="topbar__menulist__menuItem">
                
                </MenuItem>
                <MenuItem className="topbar__menulist__menuItem">
                  <Link to={"/vendor/mapview"} className="topbar__menuLink">
                    Map View
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                {/* <MenuItem className="topbar__menulist__menuItem">
                  <Link className="topbar__menuLink" to={"/vendor/camerastream"}>
                    Camera Stream 
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem> */}
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    to={"/vendor/sitestatus"}
                    className="topbar__menuLink"
                  >
                    Site Status
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
              </MenuList> 
            </Menu>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="topbar__menuBtn">      
                Reports
              </MenuButton>
              <MenuList className="topbar__menulist">

                

                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    to={"/vendor/customreport"}
                    className="topbar__menuLink"
                  >    
                    Custom Report
                  <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>

             
                
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="topbar__menuBtn"
              >
                Stations
              </MenuButton>
              <MenuList className="topbar__menulist">
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    to={"/vendor/stationstatus"}
                    className="topbar__menuLink"
                  >
                    Station Status{" "}
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
          
        

          
          </div>
          <div className="topbar__myaccount">
        
            <Menu>
              <MenuButton as={Button} className="topbar__menuBtn">
                <FaRegUserCircle className="topbar__myaccount__icon" />

                {/* {notfication ? (
                <RxDotFilled className="topbar__myaccount__icon__notificationAlert" />
              ) : null} */}


              </MenuButton>
              <MenuList className="topbar__menulist">
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    to={`/${userType}/myaccount`}
                    className="topbar__menuLink"
                  >
                    My Account{" "}
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                {/* <MenuItem className="topbar__menulist__menuItem">
                <Link
                  to={"/operator/notifications"}
                  className="topbar__menuLink"
                  style={{ position: "relative" }}
                  onClick={() => setNotifications(false)}
                >
                  Notifications{" "}
                  {notfication ? (
                    <RxDotFilled className="topbar__menulist__menuItem__notificationAlert" />
                  ) : null}
                  <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                </Link>       
              </MenuItem> */}
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    className="topbar__menuLink"
                    onClick={handleToggleFullscreen}
                  >
                    {isFullscreen
                      ? "Exit Full Screen Mode"
                      : "Full Screen Mode"}
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    className="topbar__menuLink"
                    onClick={() => setHandlePopUp(true)}
                  > 
                    Log Out
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
            {handlePopUp && <LogoutPopup setHandlePopUp={setHandlePopUp} />}
            {/* <AnimatePresence>
            {notificationPopup && (
              <NotificationPopup setNotificationPopup={setNotificationPopup} />
            )}
          </AnimatePresence> */}
          </div>
        </div>
        <div className="hamburger__menu">
          {handleResponsiveMenuToggle ? (
            <MdExitToApp
              onClick={() => setHandleResponsiveMenuToggle(false)}
              className="responsiveMenu__toggle__icon"
            />
          ) : (
            <BiMenu
              onClick={() => {
                setHandleResponsiveMenuToggle(true);
              }}
              className="responsiveMenu__toggle__icon"
            />
          )}
        </div>
        <AnimatePresence>
          {handleResponsiveMenuToggle && (
            <ResponsiveTopbar
              setHandleResponsiveMenuToggle={setHandleResponsiveMenuToggle}
              userType={userType}
            />
          )}
        </AnimatePresence>
      </>
    );
  
  }


  else if (userType === "cpcb") {
    return (
      <>
        <div className="topbar">
        <Link to={"/cpcb"}>
          <div className="topbar__logo">
            <img src={Logo} alt="" />
          </div>
          </Link>  

          <div className="topbar__menu">
            <Menu className="topbar__menuBtn">
              <Link
                to="/cpcb"
                style={{
                  textDecoration: "none",
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  marginRight: "2rem",
                  color: "#fff",
                }}
              >
                Dashboard
              </Link>
            </Menu>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="topbar__menuBtn"
              >
                Live Status
              </MenuButton>
              <MenuList className="topbar__menulist">
                <MenuItem className="topbar__menulist__menuItem">
                
                </MenuItem>
                <MenuItem className="topbar__menulist__menuItem">
                  <Link to={"/cpcb/mapview"} className="topbar__menuLink">
                    Map View
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                {/* <MenuItem className="topbar__menulist__menuItem">
                  <Link className="topbar__menuLink" to={"/cpcb/camerastream"}>
                    Camera Stream 
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem> */}
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    to={"/cpcb/sitestatus"}
                    className="topbar__menuLink"
                  >
                    Site Status
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
              </MenuList> 
            </Menu>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="topbar__menuBtn">      
                Reports
              </MenuButton>
              <MenuList className="topbar__menulist">

                

                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    to={"/cpcb/customreport"}
                    className="topbar__menuLink"
                  >    
                    Custom Report
                  <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>

             
                
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="topbar__menuBtn"
              >
                Stations
              </MenuButton>
              <MenuList className="topbar__menulist">
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    to={"/cpcb/stationstatus"}
                    className="topbar__menuLink"
                  >
                    Station Status{" "}
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
          
        

          
          </div>
          <div className="topbar__myaccount">
        
            <Menu>
              <MenuButton as={Button} className="topbar__menuBtn">
                <FaRegUserCircle className="topbar__myaccount__icon" />

                {/* {notfication ? (
                <RxDotFilled className="topbar__myaccount__icon__notificationAlert" />
              ) : null} */}


              </MenuButton>
              <MenuList className="topbar__menulist">
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    to={`/${userType}/myaccount`}
                    className="topbar__menuLink"
                  >
                    My Account{" "}
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                {/* <MenuItem className="topbar__menulist__menuItem">
                <Link
                  to={"/operator/notifications"}
                  className="topbar__menuLink"
                  style={{ position: "relative" }}
                  onClick={() => setNotifications(false)}
                >
                  Notifications{" "}
                  {notfication ? (
                    <RxDotFilled className="topbar__menulist__menuItem__notificationAlert" />
                  ) : null}
                  <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                </Link>       
              </MenuItem> */}
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    className="topbar__menuLink"
                    onClick={handleToggleFullscreen}
                  >
                    {isFullscreen
                      ? "Exit Full Screen Mode"
                      : "Full Screen Mode"}
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    className="topbar__menuLink"
                    onClick={() => setHandlePopUp(true)}
                  > 
                    Log Out
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
            {handlePopUp && <LogoutPopup setHandlePopUp={setHandlePopUp} />}
            {/* <AnimatePresence>
            {notificationPopup && (
              <NotificationPopup setNotificationPopup={setNotificationPopup} />
            )}
          </AnimatePresence> */}
          </div>
        </div>
        <div className="hamburger__menu">
          {handleResponsiveMenuToggle ? (
            <MdExitToApp
              onClick={() => setHandleResponsiveMenuToggle(false)}
              className="responsiveMenu__toggle__icon"
            />
          ) : (
            <BiMenu
              onClick={() => {
                setHandleResponsiveMenuToggle(true);
              }}
              className="responsiveMenu__toggle__icon"
            />
          )}
        </div>
        <AnimatePresence>
          {handleResponsiveMenuToggle && (
            <ResponsiveTopbar
              setHandleResponsiveMenuToggle={setHandleResponsiveMenuToggle}
              userType={userType}
            />
          )}
        </AnimatePresence>
      </>
    );
  
  
  }


  // User Navbar
  else {
    return (
      <>
        <div className="topbar">
        <Link to={"/operator"}>
          <div className="topbar__logo">
            <img src={Logo} alt="" />
          </div>
        </Link>  

          <div className="topbar__menu">
            <Menu className="topbar__menuBtn">
              <Link
                to="/operator"
                style={{
                  textDecoration: "none",
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  marginRight: "2rem",
                  color: "#fff",
                }}
              >
                Dashboard
              </Link>
            </Menu>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="topbar__menuBtn"
              >
                Live Status
              </MenuButton>
              <MenuList className="topbar__menulist">
                <MenuItem className="topbar__menulist__menuItem">
                
                </MenuItem>
                <MenuItem className="topbar__menulist__menuItem">
                  <Link to={"/operator/mapview"} className="topbar__menuLink">
                    Map View
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    to={"/operator/usercamerapage"}
                    className="topbar__menuLink"
                  >      
                    Camera Stream
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    to={"/operator/site/site-details"}
                    className="topbar__menuLink"
                  >
                    Site Status
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
              </MenuList> 
            </Menu>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="topbar__menuBtn">      
                Reports
              </MenuButton>
              <MenuList className="topbar__menulist">

                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    to={"/operator/averagereport"}
                    className="topbar__menuLink"
                  >    
                    Average Report
                  <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>

                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    to={"/operator/customreport"}
                    className="topbar__menuLink"
                  >    
                    Custom Report
                  <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>

                
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="topbar__menuBtn"
              >
                Stations
              </MenuButton>
              <MenuList className="topbar__menulist">
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    to={"/operator/stationstatus"}
                    className="topbar__menuLink"
                  >
                    Station Status{" "}
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
          
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="topbar__menuBtn" >
                WorkFlow
              </MenuButton>
              <MenuList className="topbar__menulist">
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    to={"/operator/usersideworkflowcompleted"}
                    className="topbar__menuLink"
                  >
                    WorkFlow Data{" "}
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>


          </div>
          <div className="topbar__myaccount">
          <Menu>
              <Menu className="topbar__menuBtn">

                <Link
                  to={`/${userType}/site/notifications`}
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    fontSize: "1.4rem",
                    fontWeight: "500",
                    marginRight: "2rem",
                  }}
                >
                <BellIcon /> 
                 <span className="badge">+1</span>
              
                </Link>
              </Menu>
            </Menu>
            <Menu>
              <MenuButton as={Button} className="topbar__menuBtn">
                <FaRegUserCircle className="topbar__myaccount__icon" />


                {/* {notfication ? (
                <RxDotFilled className="topbar__myaccount__icon__notificationAlert" />
              ) : null} */}


              </MenuButton>
              <MenuList className="topbar__menulist">
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    to={`/${userType}/myaccount`}
                    className="topbar__menuLink"
                  >
                    My Account{" "}
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                {/* <MenuItem className="topbar__menulist__menuItem">
                <Link
                  to={"/operator/notifications"}
                  className="topbar__menuLink"
                  style={{ position: "relative" }}
                  onClick={() => setNotifications(false)}
                >
                  Notifications{" "}
                  {notfication ? (
                    <RxDotFilled className="topbar__menulist__menuItem__notificationAlert" />
                  ) : null}
                  <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                </Link>       
              </MenuItem> */}
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    className="topbar__menuLink"
                    onClick={handleToggleFullscreen}
                  >
                    {isFullscreen
                      ? "Exit Full Screen Mode"
                      : "Full Screen Mode"}
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
                <MenuItem className="topbar__menulist__menuItem">
                  <Link
                    className="topbar__menuLink"
                    onClick={() => setHandlePopUp(true)}
                  > 
                    Log Out
                    <MdKeyboardArrowRight className="topbar__menuLink__icon" />
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
            {handlePopUp && <LogoutPopup setHandlePopUp={setHandlePopUp} />}
            {/* <AnimatePresence>
            {notificationPopup && (
              <NotificationPopup setNotificationPopup={setNotificationPopup} />
            )}
          </AnimatePresence> */}
          </div>
        </div>
        <div className="hamburger__menu">
          {handleResponsiveMenuToggle ? (
            <MdExitToApp
              onClick={() => setHandleResponsiveMenuToggle(false)}
              className="responsiveMenu__toggle__icon"
            />
          ) : (
            <BiMenu
              onClick={() => {
                setHandleResponsiveMenuToggle(true);
              }}
              className="responsiveMenu__toggle__icon"
            />
          )}
        </div>
        <AnimatePresence>
          {handleResponsiveMenuToggle && (
            <ResponsiveTopbar
              setHandleResponsiveMenuToggle={setHandleResponsiveMenuToggle}
              userType={userType}
            />
          )}
        </AnimatePresence>
      </>
    );
  }
};

export default Topbar;
    
